import React, {
  ComponentType,
  useContext,
  useMemo,
  forwardRef,
  ReactElement,
} from 'react'
import { View, StyleProp, ViewStyle, TextStyle, ImageStyle } from 'react-native'

import { TokensContext, ComponentsContext, BreakpointContext } from './context'

import { TokenValues, TokenSelection } from './types'

import { applyStyles } from './util/applyStyles'

interface ElementProps {
  as?: ComponentType
  tokens?: Partial<
    Record<keyof TokenValues, TokenSelection | Array<TokenSelection>>
  >
  component?: string
  variant?: string
  style?: StyleProp<ViewStyle | TextStyle | ImageStyle>
}

// These types have been taken from https://github.com/reach/reach-ui/blob/25840c3d15278e316cc74eaecf096fa01377f361/packages/utils/src/types.tsx#L104
export type As<BaseProps = any> = React.ElementType<BaseProps>

export type PropsWithAs<
  ComponentType extends As,
  ComponentProps
> = ComponentProps &
  Omit<
    React.ComponentPropsWithRef<ComponentType>,
    'as' | keyof ComponentProps
  > & {
    as?: ComponentType
  }

export interface ComponentWithAs<ComponentType extends As, ComponentProps> {
  // These types are a bit of a hack, but cover us in cases where the `as` prop
  // is not a JSX string type. Makes the compiler happy so 🤷‍♂️
  <TT extends As>(props: PropsWithAs<TT, ComponentProps>): ReactElement | null
  (props: PropsWithAs<ComponentType, ComponentProps>): ReactElement | null

  displayName?: string
  propTypes?: React.WeakValidationMap<
    PropsWithAs<ComponentType, ComponentProps>
  >
  contextTypes?: React.ValidationMap<any>
  defaultProps?: Partial<PropsWithAs<ComponentType, ComponentProps>>
}

export type PropsFromAs<ComponentType extends As, ComponentProps> = PropsWithAs<
  ComponentType,
  ComponentProps
> & { as: ComponentType }

export function forwardRefWithAs<Props, ComponentType extends As>(
  comp: (
    props: PropsFromAs<ComponentType, Props>,
    ref: React.RefObject<any>
  ) => React.ReactElement | null
) {
  return forwardRef(comp as any) as unknown as ComponentWithAs<
    ComponentType,
    Props
  >
}

export const Element = forwardRefWithAs<ElementProps, any>(
  ({ as: Comp = View, tokens = {}, component, variant, ...props }, ref) => {
    const tokensContext = useContext(TokensContext)
    const componentsContext = useContext(ComponentsContext)
    const breakpointContext = useContext(BreakpointContext)

    const styles = useMemo(() => {
      return applyStyles(
        tokensContext,
        componentsContext,
        breakpointContext,
        tokens,
        component,
        variant
      )
    }, [
      tokensContext,
      componentsContext,
      breakpointContext,
      component,
      variant,
      tokens,
    ])

    const finalStyles = useMemo(() => {
      return [styles, props.style].flat(Infinity)
    }, [styles, props.style])

    return <Comp ref={ref} {...props} style={finalStyles} />
  }
)
