import React, {
  ComponentProps,
  FC,
  useCallback,
  useMemo,
  useEffect,
  useState,
  useContext,
  createContext,
} from 'react'
import {
  Modal as RNModal,
  ScrollView,
  Pressable,
  TouchableOpacity,
} from 'react-native'

import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated'
import { useLocation, useNavigate } from 'react-router-dom'

import { Element } from './Element'
import { Icon } from './Icon'

const ModalContext = createContext<undefined | { close: () => any }>(undefined)

interface ModalProps extends ComponentProps<typeof RNModal> {
  variant?: string
}

export const Modal = ({ children, variant, ...props }: ModalProps) => {
  const backgroundBlur = useSharedValue(0)
  const modalOpacity = useSharedValue(0)

  const [requestingClose, setRequestingClose] = useState(false)

  useEffect(() => {
    if (requestingClose) {
      backgroundBlur.value = withTiming(0, { duration: 250 })
      modalOpacity.value = withTiming(0.0, { duration: 250 }, () => {
        if (props.onRequestClose) {
          props.onRequestClose()
        }
      })
    } else {
      backgroundBlur.value = withTiming(16, { duration: 250 })
      modalOpacity.value = withTiming(1.0, { duration: 250 })
    }
  }, [requestingClose, props.onRequestClose])

  const backgroundStyle = useAnimatedStyle(() => {
    return {
      alignItems: 'center',
      justifyContent: 'center',

      willChange: 'backdropFilter, opacity',
      backdropFilter: `blur(${backgroundBlur.value}px) saturate(250%)`,
      WebkitBackdropFilter: `blur(${backgroundBlur.value}px) saturate(250%)`,
      opacity: modalOpacity.value,
      // transform: [{ translateZ: 0 }],
    }
  }, [])

  const modalStyle = useAnimatedStyle(() => {
    return {
      opacity: modalOpacity.value,
    }
  }, [])

  const requestClose = useCallback(() => {
    setRequestingClose(true)
  }, [setRequestingClose])

  const contextValue = useMemo(() => {
    return { close: requestClose }
  }, [requestClose])

  return (
    <ModalContext.Provider value={contextValue}>
      <Element style={{ flex: 1 }} {...props} as={RNModal} transparent>
        <Pressable
          onPress={() => {
            setRequestingClose(true)
          }}
          style={{ flex: 1 }}
        >
          <Element as={Animated.View} component='Modal' style={backgroundStyle}>
            <Pressable
              onPress={() => {}}
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                maxHeight: 'calc(100vh - 48px)',
                maxWidth: 'calc(100vw - 48px)',
              }}
            >
              <Element
                as={Animated.View}
                component='ModalWrapper'
                style={modalStyle}
                variant={variant}
              >
                <Element
                  as={TouchableOpacity}
                  component='ModalCloseButton'
                  onPress={() => {
                    setRequestingClose(true)
                  }}
                >
                  <Icon name='dismiss' size={4} variant='modalCloseButton' />
                </Element>
                <Element as={ScrollView} component='ModalScroll'>
                  <Element component='ModalContents' variant={variant}>
                    {children}
                  </Element>
                </Element>
              </Element>
            </Pressable>
          </Element>
        </Pressable>
      </Element>
    </ModalContext.Provider>
  )
}

export const ModalWrapper = ({
  children,
  variant,
}: {
  variant?: string
  children: React.ReactNode
}) => {
  const location = useLocation()
  const navigate = useNavigate()

  const handleRequestClose = useCallback(() => {
    const locationState = location.state as
      | { backgroundLocation: Location }
      | undefined

    if (!locationState) {
      console.warn('WARNING: Modal closed without setting backgroundLocation')
      navigate(-1)
      return
    }

    navigate(locationState.backgroundLocation)
  }, [location.state, navigate])

  return (
    <Modal variant={variant} onRequestClose={handleRequestClose} visible>
      {children}
    </Modal>
  )
}

export const useModal = () => {
  return useContext(ModalContext)
}

export const useInModal = () => {
  return !!useContext(ModalContext)
}
