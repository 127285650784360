import React, { FC } from 'react'

import { Stack } from '../Stack'
import { Text } from '../Text'
import { LogoIconAnimated } from './LogoIconAnimated'

export const LogoAnimated = ({ animate = false }: { animate: boolean }) => {
  return (
    <Stack direction='horizontal' alignItems='center' gap={2}>
      <LogoIconAnimated animate={animate} />

      <Text size={6} variant='hero'>
        Guild
      </Text>
    </Stack>
  )
}
