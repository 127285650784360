import React, { FC, useCallback, useState, useEffect } from 'react'
import {
  LayoutChangeEvent,
  View,
  TouchableOpacity,
  LayoutRectangle,
} from 'react-native'

import { Location, useLocation, useNavigate } from 'react-router-dom'

import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withSpring,
  interpolate,
  WithSpringConfig,
} from 'react-native-reanimated'

import { Stack } from '../Stack'
import { Text } from '../Text'
import { Link } from '../Link'
import { Icon } from '../Icon'
import { Button } from '../Button'

import { useAuthContext } from '@data/useAuthContext'

export const DynamicIslandAdd: FC<{
  onButtonLayout: (event: LayoutChangeEvent) => any
  onButtonPress: (event: any) => any
  onDropDownLayout: (event: LayoutChangeEvent) => any
  expandDropdown: boolean
  setExpandedDropdown: (dropdown: 'home' | 'add' | 'profile' | null) => any
  buttonContainerStyle: {}
  buttonStyle: {}
  dropDownStyle: {}
}> = ({
  onButtonLayout,
  onButtonPress,
  onDropDownLayout,
  expandDropdown,
  setExpandedDropdown,
  buttonContainerStyle,
  buttonStyle,
  dropDownStyle,
}) => {
  const { accessToken } = useAuthContext()

  const navigate = useNavigate()
  const location = useLocation()

  const [guildLayout, setGuildLayout] = useState<LayoutRectangle | undefined>()
  const [eventLayout, setEventLayout] = useState<LayoutRectangle | undefined>()
  const [presentationLayout, setPresentationLayout] = useState<
    LayoutRectangle | undefined
  >()

  const [hoveredButton, setHoveredButton] = useState<
    'event' | 'presentation' | 'guild' | null
  >(null)

  const backgroundHoverTop = useSharedValue(0)
  const backgroundHoverOpacity = useSharedValue(0)

  const closeDropDown = useCallback(() => {
    setExpandedDropdown(null)
    setHoveredButton(null)
  }, [setExpandedDropdown, setHoveredButton])

  useEffect(() => {
    const springOptions: WithSpringConfig = { damping: 100, stiffness: 200 }

    if (!hoveredButton || !eventLayout || !guildLayout || !presentationLayout) {
      backgroundHoverOpacity.value = withSpring(0, {
        ...springOptions,
        overshootClamping: true,
      })
      return
    }

    backgroundHoverOpacity.value = withSpring(1, {
      ...springOptions,
      overshootClamping: true,
    })

    switch (hoveredButton) {
      case 'event': {
        backgroundHoverTop.value = withSpring(eventLayout.y, springOptions)
        break
      }
      case 'presentation': {
        backgroundHoverTop.value = withSpring(
          presentationLayout.y,
          springOptions
        )
        break
      }
      case 'guild': {
        backgroundHoverTop.value = withSpring(guildLayout.y, springOptions)
        break
      }
    }
  }, [hoveredButton, eventLayout, presentationLayout, guildLayout])

  const backgroundHoverStyle = useAnimatedStyle(() => {
    return {
      position: 'absolute',
      backgroundColor: 'rgba(85, 51, 235, 0.1)',
      width: 'calc(100% + 8px)',
      height: 40,
      borderRadius: 40 / 2,
      justifyContent: 'center',
      padding: 4,
      marginLeft: -4,
      marginRight: -4,

      transform: [{ translateY: backgroundHoverTop.value - 4 }],
      opacity: backgroundHoverOpacity.value,
    }
  }, [])

  const navigateOrLogin = (path: string) => () => {
    const backgroundLocation =
      (location.state?.backgroundLocation as Location) || location

    if (accessToken) {
      navigate(path, { state: { backgroundLocation } })
    } else {
      navigate('/sign-up', {
        state: { backgroundLocation, authRedirectPath: path },
      })
    }

    closeDropDown()
  }

  return (
    <View onLayout={onButtonLayout}>
      <Animated.View style={buttonContainerStyle}>
        <Button variant='icon' hoverVariant='iconHover' onPress={onButtonPress}>
          <Animated.View style={buttonStyle}>
            <Icon name='add' size={5.5} />
          </Animated.View>
        </Button>
      </Animated.View>

      {expandDropdown && (
        <Animated.View style={dropDownStyle}>
          <View
            style={{
              position: 'absolute',

              right: 0,

              minWidth: 190,
              cursor: 'pointer',

              paddingVertical: 8,
              paddingHorizontal: 16,
            }}
            onLayout={onDropDownLayout}
            onMouseLeave={() => setHoveredButton(null)}
          >
            <Stack gap={3}>
              <Animated.View style={backgroundHoverStyle} />

              <View
                onMouseEnter={() => {
                  setHoveredButton('event')
                }}
                onLayout={(event) => {
                  setEventLayout(event.nativeEvent.layout)
                }}
              >
                <TouchableOpacity onPress={navigateOrLogin('/events/new')}>
                  <Stack gap={3} direction='horizontal' alignItems='center'>
                    <Button
                      variant={
                        hoveredButton === 'event'
                          ? 'dynamicIslandHover'
                          : 'dynamicIsland'
                      }
                    >
                      <Icon name='calendar' />
                    </Button>

                    <Text size={5} variant='noWrap'>
                      Event
                    </Text>
                  </Stack>
                </TouchableOpacity>
              </View>
              <View
                onMouseEnter={() => {
                  setHoveredButton('presentation')
                }}
                onLayout={(event) => {
                  setPresentationLayout(event.nativeEvent.layout)
                }}
              >
                <TouchableOpacity
                  onPress={navigateOrLogin('/presentations/new')}
                >
                  <Stack gap={3} direction='horizontal' alignItems='center'>
                    <Button
                      variant={
                        hoveredButton === 'presentation'
                          ? 'dynamicIslandHover'
                          : 'dynamicIsland'
                      }
                    >
                      <Icon name='projection-screen' />
                    </Button>

                    <Text size={5} variant='noWrap'>
                      Presentation
                    </Text>
                  </Stack>
                </TouchableOpacity>
              </View>
              <View
                onMouseEnter={() => {
                  setHoveredButton('guild')
                }}
                onLayout={(event) => {
                  setGuildLayout(event.nativeEvent.layout)
                }}
              >
                <TouchableOpacity onPress={navigateOrLogin('/guilds/new')}>
                  <Stack gap={3} direction='horizontal' alignItems='center'>
                    <Button
                      variant={
                        hoveredButton === 'guild'
                          ? 'dynamicIslandHover'
                          : 'dynamicIsland'
                      }
                    >
                      <Icon name='people-community' />
                    </Button>

                    <Text size={5} variant='noWrap'>
                      Guild
                    </Text>
                  </Stack>
                </TouchableOpacity>
              </View>
            </Stack>
          </View>
        </Animated.View>
      )}
    </View>
  )
}
