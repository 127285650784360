import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  LayoutChangeEvent,
  View,
  TouchableOpacity,
  LayoutRectangle,
} from 'react-native'
import { useFragment, graphql } from 'react-relay'
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withSpring,
  interpolate,
  WithSpringConfig,
} from 'react-native-reanimated'

import { useLocation, useNavigate, useMatch, Location } from 'react-router-dom'

import { setUser } from '@sentry/react'

import { Stack } from '../Stack'
import { Text } from '../Text'
import { Link } from '../Link'
import { Icon } from '../Icon'
import { Button } from '../Button'
import { UserPrimaryPhoto } from '../User/UserPrimaryPhoto'

import { useAuthContext } from '@data/useAuthContext'

import { DynamicIslandProfile_query$key } from './__generated__/DynamicIslandProfile_query.graphql'

export const DynamicIslandProfile: FC<{
  onButtonLayout: (event: LayoutChangeEvent) => any
  onButtonPress: (event: any) => any
  onDropDownLayout: (event: LayoutChangeEvent) => any
  expandDropdown: boolean
  setExpandedDropdown: (dropdown: 'home' | 'add' | 'profile' | null) => any
  buttonContainerStyle: {}
  dropDownStyle: {}
  query: DynamicIslandProfile_query$key
}> = ({
  onButtonLayout,
  onButtonPress,
  onDropDownLayout,
  expandDropdown,
  setExpandedDropdown,
  buttonContainerStyle,
  dropDownStyle,
  query,
}) => {
  const data = useFragment(
    graphql`
      fragment DynamicIslandProfile_query on Query {
        viewer {
          rowId
          slugId

          ...UserPrimaryPhoto_user
        }
      }
    `,
    query
  )

  const location = useLocation()
  const navigate = useNavigate()

  const { setAuthTokens } = useAuthContext()

  const mainButtonOpacity = useSharedValue(1.0)
  const mainButtonRotation = useSharedValue(0)

  useEffect(() => {
    if (data.viewer) {
      setUser({ username: data.viewer.slugId })
    } else {
      setUser(null)
    }
  }, [data.viewer])

  useEffect(() => {
    const springOptions = { mass: 0.15, stiffness: 60, damping: 5 }

    if (expandDropdown) {
      mainButtonOpacity.value = withSpring(0.0, {
        ...springOptions,
        overshootClamping: true,
      })
      mainButtonRotation.value = withSpring(45, springOptions)
    } else {
      mainButtonOpacity.value = withSpring(1.0, {
        ...springOptions,
        overshootClamping: true,
      })
      mainButtonRotation.value = withSpring(0, springOptions)
    }
  }, [expandDropdown])

  const mainButtonStyle = useAnimatedStyle(() => {
    return {
      position: 'absolute',

      opacity: mainButtonOpacity.value,
      transform: [
        { rotate: `${mainButtonRotation.value}deg` },
        { translateZ: 0 },
      ],
    }
  }, [])

  const closeButtonStyle = useAnimatedStyle(() => {
    return {
      position: 'absolute',

      opacity: interpolate(mainButtonOpacity.value, [0.0, 1.0], [1.0, 0.0]),
      transform: [
        { rotate: `${mainButtonRotation.value}deg` },
        { translateZ: 0 },
      ],
    }
  }, [])

  const [signInLayout, setSignInLayout] = useState<
    LayoutRectangle | undefined
  >()
  const [signUpLayout, setSignUpLayout] = useState<
    LayoutRectangle | undefined
  >()
  const [profileLayout, setProfileLayout] = useState<
    LayoutRectangle | undefined
  >()
  const [settingsLayout, setSettingsLayout] = useState<
    LayoutRectangle | undefined
  >()
  const [signOutLayout, setSignOutLayout] = useState<
    LayoutRectangle | undefined
  >()

  const profileMatch = useMatch(`/${data.viewer?.slugId}`)
  const settingsMatch = useMatch(`/${data.viewer?.slugId}/settings`)

  const [hoveredButton, setHoveredButton] = useState<
    'sign-in' | 'sign-up' | 'profile' | 'settings' | 'sign-out' | null
  >(() => {
    if (profileMatch) {
      return 'profile'
    } else if (settingsMatch) {
      return 'settings'
    } else {
      return null
    }
  })

  const backgroundHoverTop = useSharedValue(0)
  const backgroundHoverOpacity = useSharedValue(0)

  useEffect(() => {
    const springOptions: WithSpringConfig = { damping: 100, stiffness: 200 }

    if (data.viewer) {
      const matchedPath = (() => {
        if (profileMatch) {
          return 'profile'
        } else if (settingsMatch) {
          return 'settings'
        } else {
          return null
        }
      })()

      if (
        (!hoveredButton && !matchedPath) ||
        !profileLayout ||
        !settingsLayout ||
        !signOutLayout
      ) {
        backgroundHoverOpacity.value = withSpring(0, {
          ...springOptions,
          overshootClamping: true,
        })
        return
      }

      backgroundHoverOpacity.value = withSpring(1, {
        ...springOptions,
        overshootClamping: true,
      })

      switch (hoveredButton || matchedPath) {
        case 'profile': {
          backgroundHoverTop.value = withSpring(profileLayout.y, springOptions)
          break
        }
        case 'settings': {
          backgroundHoverTop.value = withSpring(settingsLayout.y, springOptions)
          break
        }
        case 'sign-out': {
          backgroundHoverTop.value = withSpring(signOutLayout.y, springOptions)
        }
      }
    } else {
      if (!hoveredButton || !signInLayout || !signUpLayout) {
        backgroundHoverOpacity.value = withSpring(0, {
          ...springOptions,
          overshootClamping: true,
        })
        return
      }

      backgroundHoverOpacity.value = withSpring(1, {
        ...springOptions,
        overshootClamping: true,
      })

      switch (hoveredButton) {
        case 'sign-in': {
          backgroundHoverTop.value = withSpring(signInLayout.y, springOptions)
          break
        }
        case 'sign-up': {
          backgroundHoverTop.value = withSpring(signUpLayout.y, springOptions)
        }
      }
    }
  }, [
    hoveredButton,
    data,
    signInLayout,
    signUpLayout,
    profileLayout,
    settingsLayout,
    signOutLayout,
    profileMatch,
    settingsMatch,
  ])

  const backgroundHoverStyle = useAnimatedStyle(() => {
    return {
      position: 'absolute',
      backgroundColor: 'rgba(85, 51, 235, 0.1)',
      width: 'calc(100% + 8px)',
      height: 40,
      borderRadius: 40 / 2,
      justifyContent: 'center',
      padding: 4,
      marginLeft: -4,
      marginRight: -4,

      transform: [{ translateY: backgroundHoverTop.value - 4 }],
      opacity: backgroundHoverOpacity.value,
    }
  }, [])

  const closeDropDown = useCallback(() => {
    setExpandedDropdown(null)
    setHoveredButton(null)
  }, [setExpandedDropdown, setHoveredButton])

  return (
    <View onLayout={onButtonLayout}>
      <Animated.View style={buttonContainerStyle}>
        <Button
          key={data.viewer ? 'viewer' : 'no-viewer'}
          variant='icon'
          hoverVariant='iconHover'
          onPress={onButtonPress}
        >
          <Animated.View style={mainButtonStyle}>
            {data.viewer ? (
              <UserPrimaryPhoto
                user={data.viewer}
                size={8}
                variant='noOuterBorder'
              />
            ) : (
              <Icon name='person' size={5.5} />
            )}
          </Animated.View>

          <Animated.View style={closeButtonStyle}>
            <Icon name='add' size={5.5} />
          </Animated.View>
        </Button>
      </Animated.View>

      {expandDropdown && (
        <Animated.View style={dropDownStyle}>
          <View
            style={{
              position: 'absolute',

              right: 0,

              paddingVertical: 8,
              paddingHorizontal: 16,

              minWidth: 160,
              cursor: 'pointer',
            }}
            onLayout={onDropDownLayout}
            onMouseLeave={() => {
              if (data.viewer) {
                if (profileMatch) {
                  setHoveredButton('profile')
                } else if (settingsMatch) {
                  setHoveredButton('settings')
                } else {
                  setHoveredButton(null)
                }
              } else {
                setHoveredButton(null)
              }
            }}
          >
            <Stack gap={3}>
              <Animated.View style={backgroundHoverStyle} />

              {data.viewer ? (
                <>
                  <View
                    onMouseEnter={() => {
                      setHoveredButton('profile')
                    }}
                    onLayout={(event) => {
                      setProfileLayout(event.nativeEvent.layout)
                    }}
                  >
                    <Link
                      to={`/${data.viewer.slugId}`}
                      beforeNavigate={closeDropDown}
                    >
                      <Stack gap={3} direction='horizontal' alignItems='center'>
                        <UserPrimaryPhoto
                          user={data.viewer}
                          size={8}
                          variant='noOuterBorder'
                        />

                        <Text size={5} variant='noWrap'>
                          Profile
                        </Text>
                      </Stack>
                    </Link>
                  </View>
                  <View
                    onMouseEnter={() => {
                      setHoveredButton('settings')
                    }}
                    onLayout={(event) => {
                      setSettingsLayout(event.nativeEvent.layout)
                    }}
                  >
                    <Link
                      to={`/${data.viewer.slugId}/settings`}
                      beforeNavigate={closeDropDown}
                    >
                      <Stack gap={3} direction='horizontal' alignItems='center'>
                        <Button
                          variant={
                            hoveredButton === 'settings'
                              ? 'dynamicIslandHover'
                              : 'dynamicIsland'
                          }
                        >
                          <Icon name='settings-filled' />
                        </Button>

                        <Text size={5} variant='noWrap'>
                          Settings
                        </Text>
                      </Stack>
                    </Link>
                  </View>

                  <View
                    onMouseEnter={() => {
                      setHoveredButton('sign-out')
                    }}
                    onLayout={(event) => {
                      setSignOutLayout(event.nativeEvent.layout)
                    }}
                  >
                    <TouchableOpacity
                      onPress={async () => {
                        await setAuthTokens(undefined, undefined)

                        navigate('/')

                        closeDropDown()
                      }}
                    >
                      <Stack gap={3} direction='horizontal' alignItems='center'>
                        <Button
                          variant={
                            hoveredButton === 'sign-out'
                              ? 'dynamicIslandHover'
                              : 'dynamicIsland'
                          }
                        >
                          <Icon name='sign-out-filled' />
                        </Button>

                        <Text size={5} variant='noWrap'>
                          Sign Out
                        </Text>
                      </Stack>
                    </TouchableOpacity>
                  </View>
                </>
              ) : (
                <UnauthenticatedDropDown
                  closeDropDown={closeDropDown}
                  location={location}
                  hoveredButton={hoveredButton}
                  setHoveredButton={setHoveredButton}
                  setSignInLayout={setSignInLayout}
                  setSignUpLayout={setSignUpLayout}
                />
              )}
            </Stack>
          </View>
        </Animated.View>
      )}
    </View>
  )
}

const UnauthenticatedDropDown: FC<{
  closeDropDown: () => any
  location: Location
  hoveredButton: 'sign-in' | 'sign-up' | string | null
  setHoveredButton: (button: 'sign-in' | 'sign-up' | null) => any
  setSignInLayout: (rect: LayoutRectangle) => any
  setSignUpLayout: (rect: LayoutRectangle) => any
}> = ({
  closeDropDown,
  location,
  hoveredButton,
  setHoveredButton,
  setSignInLayout,
  setSignUpLayout,
}) => {
  return (
    <>
      <View
        onMouseEnter={() => {
          setHoveredButton('sign-in')
        }}
        onLayout={(event) => {
          setSignInLayout(event.nativeEvent.layout)
        }}
      >
        <Link
          to='/sign-in'
          beforeNavigate={closeDropDown}
          options={{ state: { backgroundLocation: location } }}
        >
          <Stack gap={3} direction='horizontal' alignItems='center'>
            <Button
              variant={
                hoveredButton === 'sign-in'
                  ? 'dynamicIslandHover'
                  : 'dynamicIsland'
              }
            >
              <Icon name='person' />
            </Button>

            <Text size={5} variant='noWrap'>
              Sign In
            </Text>
          </Stack>
        </Link>
      </View>

      <View
        onMouseEnter={() => {
          setHoveredButton('sign-up')
        }}
        onLayout={(event) => {
          setSignUpLayout(event.nativeEvent.layout)
        }}
      >
        <Link
          to='/sign-up'
          beforeNavigate={closeDropDown}
          options={{ state: { backgroundLocation: location } }}
          style={{ width: '100%' }}
        >
          <Stack gap={3} direction='horizontal' alignItems='center'>
            <Button
              variant={
                hoveredButton === 'sign-up'
                  ? 'dynamicIslandHover'
                  : 'dynamicIsland'
              }
            >
              <Icon name='add' />
            </Button>

            <Text size={5} variant='noWrap'>
              Sign Up
            </Text>
          </Stack>
        </Link>
      </View>
    </>
  )
}
