export const pickFromDefaults = (
  dbRowId: string | undefined,
  defaults: string[]
): string => {
  const rowId = dbRowId?.substring(0, 8) || '0'
  return defaults[
    // convert the first part of the user's stable UUID into a number
    // then mod it by the amount of avatars we have
    // in order to select a constant avatar for a user
    parseInt(rowId, 16) % defaults.length
  ]
}
