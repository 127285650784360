import React, { Suspense } from 'react'

import { YStack } from 'tamagui'
import type { LoadableComponent } from '@loadable/component'

import { Spinner } from './Spinner'

export const wrapSuspenseComponent = <T extends {}>(
  Comp: LoadableComponent<T>
) => {
  return (props: T) => {
    return (
      <Suspense fallback={<Spinner size='$9' color='$darkBlue9' />}>
        <Comp {...props} />
      </Suspense>
    )
  }
}

export const wrapSuspenseDialog = <T extends {}>(
  Comp: LoadableComponent<T>
) => {
  return (props: T) => {
    return (
      <Suspense
        fallback={
          <YStack flex={1} alignItems='center' justifyContent='center'>
            <Spinner size='$9' color='$darkBlue9' />
          </YStack>
        }
      >
        <Comp {...props} />
      </Suspense>
    )
  }
}
