import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  LayoutChangeEvent,
  View,
  TouchableOpacity,
  LayoutRectangle,
} from 'react-native'

import { useMatch } from 'react-router-dom'

import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withSpring,
  interpolate,
  WithSpringConfig,
} from 'react-native-reanimated'

import { Stack } from '../Stack'
import { Text } from '../Text'
import { Link } from '../Link'
import { Icon } from '../Icon'
import { Button } from '../Button'

export const DynamicIslandHome: FC<{
  onButtonLayout: (event: LayoutChangeEvent) => any
  onButtonPress: (event: any) => any
  onDropDownLayout: (event: LayoutChangeEvent) => any
  expandDropdown: boolean
  setExpandedDropdown: (dropdown: 'home' | 'add' | 'profile' | null) => any
  buttonContainerStyle: {}
  dropDownStyle: {}
}> = ({
  onButtonLayout,
  onButtonPress,
  onDropDownLayout,
  expandDropdown,
  setExpandedDropdown,
  buttonContainerStyle,
  dropDownStyle,
}) => {
  const mainButtonOpacity = useSharedValue(1.0)
  const mainButtonRotation = useSharedValue(0)

  const [guildsLayout, setGuildsLayout] = useState<
    LayoutRectangle | undefined
  >()
  const [eventsLayout, setEventsLayout] = useState<
    LayoutRectangle | undefined
  >()
  const [presentationsLayout, setPresentationsLayout] = useState<
    LayoutRectangle | undefined
  >()

  const guildsMatch = useMatch('/guilds')
  const eventsMatch = useMatch('/events')
  const presentationsMatch = useMatch('/presentations')

  const [hoveredButton, setHoveredButton] = useState<
    'guilds' | 'events' | 'presentations' | null
  >(() => {
    if (guildsMatch) {
      return 'guilds'
    } else if (eventsMatch) {
      return 'events'
    } else if (presentationsMatch) {
      return 'presentations'
    } else {
      return null
    }
  })

  const backgroundHoverTop = useSharedValue(0)
  const backgroundHoverOpacity = useSharedValue(0)

  useEffect(() => {
    const springOptions = { mass: 0.15, stiffness: 60, damping: 5 }

    if (expandDropdown) {
      mainButtonOpacity.value = withSpring(0.0, {
        ...springOptions,
        overshootClamping: true,
      })
      mainButtonRotation.value = withSpring(45, springOptions)
    } else {
      mainButtonOpacity.value = withSpring(1.0, {
        ...springOptions,
        overshootClamping: true,
      })
      mainButtonRotation.value = withSpring(0, springOptions)
    }
  }, [expandDropdown])

  const mainButtonStyle = useAnimatedStyle(() => {
    return {
      position: 'absolute',

      opacity: mainButtonOpacity.value,
      transform: [{ rotate: `${mainButtonRotation.value}deg` }],
    }
  }, [])

  const closeButtonStyle = useAnimatedStyle(() => {
    return {
      position: 'absolute',

      opacity: 1.0 - mainButtonOpacity.value,
      transform: [{ rotate: `${mainButtonRotation.value}deg` }],
    }
  }, [])

  const closeDropDown = useCallback(() => {
    setExpandedDropdown(null)
    setHoveredButton(null)
  }, [setExpandedDropdown, setHoveredButton])

  useEffect(() => {
    const springOptions: WithSpringConfig = { damping: 100, stiffness: 200 }

    const matchedPath = (() => {
      if (guildsMatch) {
        return 'guilds'
      } else if (eventsMatch) {
        return 'events'
      } else if (presentationsMatch) {
        return 'presentations'
      } else {
        return null
      }
    })()

    if (
      (!hoveredButton && !matchedPath) ||
      !guildsLayout ||
      !eventsLayout ||
      !presentationsLayout
    ) {
      backgroundHoverOpacity.value = withSpring(0, {
        ...springOptions,
        overshootClamping: true,
      })
      return
    }

    backgroundHoverOpacity.value = withSpring(1, {
      ...springOptions,
      overshootClamping: true,
    })

    switch (hoveredButton || matchedPath) {
      case 'guilds': {
        backgroundHoverTop.value = withSpring(guildsLayout.y, springOptions)
        break
      }
      case 'events': {
        backgroundHoverTop.value = withSpring(eventsLayout.y, springOptions)
        break
      }
      case 'presentations': {
        backgroundHoverTop.value = withSpring(
          presentationsLayout.y,
          springOptions
        )
        break
      }
    }
  }, [
    hoveredButton,
    guildsLayout,
    eventsLayout,
    presentationsLayout,
    guildsMatch,
    eventsMatch,
    presentationsMatch,
  ])

  const backgroundHoverStyle = useAnimatedStyle(() => {
    return {
      position: 'absolute',
      backgroundColor: 'rgba(85, 51, 235, 0.1)',
      width: 'calc(100% + 8px)',
      height: 40,
      borderRadius: 40 / 2,
      justifyContent: 'center',
      padding: 4,
      marginLeft: -4,
      marginRight: -4,

      transform: [{ translateY: backgroundHoverTop.value - 4 }],
      opacity: backgroundHoverOpacity.value,
    }
  }, [])

  return (
    <View onLayout={onButtonLayout}>
      <Animated.View style={buttonContainerStyle}>
        <Button variant='icon' hoverVariant='iconHover' onPress={onButtonPress}>
          <Animated.View style={mainButtonStyle}>
            <Icon name='navigation' />
          </Animated.View>

          <Animated.View style={closeButtonStyle}>
            <Icon name='add' size={5.5} />
          </Animated.View>
        </Button>
      </Animated.View>

      {expandDropdown && (
        <Animated.View style={dropDownStyle}>
          <View
            style={{
              position: 'absolute',

              right: 0,

              minWidth: 200,
              cursor: 'pointer',

              paddingVertical: 8,
              paddingHorizontal: 16,
            }}
            onLayout={onDropDownLayout}
            onMouseLeave={() => {
              if (guildsMatch) {
                setHoveredButton('guilds')
              } else if (eventsMatch) {
                setHoveredButton('events')
              } else if (presentationsMatch) {
                setHoveredButton('presentations')
              } else {
                setHoveredButton(null)
              }
            }}
          >
            <Stack gap={3} variant='fullWidth'>
              <Animated.View style={backgroundHoverStyle} />

              <View
                onMouseEnter={() => {
                  setHoveredButton('guilds')
                }}
                onLayout={(event) => {
                  setGuildsLayout(event.nativeEvent.layout)
                }}
              >
                <Link to='/guilds' beforeNavigate={closeDropDown}>
                  <Stack gap={3} direction='horizontal' alignItems='center'>
                    <Button
                      variant={
                        hoveredButton === 'guilds'
                          ? 'dynamicIslandHover'
                          : 'dynamicIsland'
                      }
                    >
                      <Icon name='people' />
                    </Button>

                    <Text size={5} variant='noWrap'>
                      Guilds
                    </Text>
                  </Stack>
                </Link>
              </View>

              <View
                onMouseEnter={() => {
                  setHoveredButton('events')
                }}
                onLayout={(event) => {
                  setEventsLayout(event.nativeEvent.layout)
                }}
              >
                <Link to='/events' beforeNavigate={closeDropDown}>
                  <Stack gap={3} direction='horizontal' alignItems='center'>
                    <Button
                      variant={
                        hoveredButton === 'events'
                          ? 'dynamicIslandHover'
                          : 'dynamicIsland'
                      }
                    >
                      <Icon name='calendar' />
                    </Button>

                    <Text size={5} variant='noWrap'>
                      Events
                    </Text>
                  </Stack>
                </Link>
              </View>

              <View
                onMouseEnter={() => {
                  setHoveredButton('presentations')
                }}
                onLayout={(event) => {
                  setPresentationsLayout(event.nativeEvent.layout)
                }}
              >
                <Link to='/presentations' beforeNavigate={closeDropDown}>
                  <Stack gap={3} direction='horizontal' alignItems='center'>
                    <Button
                      variant={
                        hoveredButton === 'presentations'
                          ? 'dynamicIslandHover'
                          : 'dynamicIsland'
                      }
                    >
                      <Icon name='projection-screen' />
                    </Button>

                    <Text size={5} variant='noWrap'>
                      Presentations
                    </Text>
                  </Stack>
                </Link>
              </View>
            </Stack>
          </View>
        </Animated.View>
      )}
    </View>
  )
}
