import React, { FC } from 'react'
import { graphql, useFragment } from 'react-relay'

import { UserDefaultPhoto } from './UserDefaultPhoto'

import { GraphQLImage } from '../GraphQLImage'

import { UserPrimaryPhoto_user$key } from './__generated__/UserPrimaryPhoto_user.graphql'
import { Element } from '@components/Element'
import { Stack } from '@components/Stack'

export const UserPrimaryPhoto: FC<{
  size?: number
  user: UserPrimaryPhoto_user$key
  variant?: string
}> = ({ user, size = 12, variant }) => {
  const data = useFragment(
    graphql`
      fragment UserPrimaryPhoto_user on User {
        ...UserDefaultPhoto_user

        primaryPhoto {
          ...GraphQLImage_image
        }
      }
    `,
    user
  )

  return (
    <Stack>
      <Element component='UserPrimaryPhoto' variant={variant}>
        {data?.primaryPhoto ? (
          <GraphQLImage
            component='ActorCardPrimaryPhoto'
            variant={variant ? variant : size > 10 ? 'userLarge' : 'userSmall'}
            image={data.primaryPhoto}
            maxWidth={size}
            maxHeight={size}
            borderRadius='max'
            focus='auto'
          />
        ) : (
          <UserDefaultPhoto size={size} user={data} />
        )}
      </Element>
    </Stack>
  )
}
