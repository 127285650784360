import React, { FC, useEffect, useState } from 'react'
import { Image } from 'react-native'

import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  interpolate,
  Extrapolate,
  WithSpringConfig,
} from 'react-native-reanimated'

import logoImageBackground from './images/guild-icon-background.svg'
import logoIconRightTop from './images/guild-icon-right-top.svg'
import logoIconRightBottom from './images/guild-icon-right-bottom.svg'
import logoIconLeft from './images/guild-icon-left.svg'

export const LogoIconAnimated = ({
  size = 8,
  animate = false,
}: {
  size?: number
  animate?: boolean
}) => {
  const backgroundAnimation = useSharedValue(1)
  const leftAnimation = useSharedValue(1)
  const rightTopAnimation = useSharedValue(1)
  const rightBottomAnimation = useSharedValue(1)

  useEffect(() => {
    if (animate) {
      let inEffect = true

      const springOptions: WithSpringConfig = { damping: 1000 }

      const animation = async () => {
        return new Promise<void>((resolve) => {
          leftAnimation.value = withSpring(0)
          rightTopAnimation.value = withSpring(0)
          rightBottomAnimation.value = withSpring(0, {}, () => {
            leftAnimation.value = withSpring(1, springOptions, () => {
              rightBottomAnimation.value = withSpring(1, springOptions, () => {
                rightTopAnimation.value = withSpring(1, springOptions, () =>
                  resolve()
                )
              })
            })
          })
        })
      }

      const loop = async () => {
        while (inEffect) {
          // if (inEffect) {
          await animation()
          // }

          await new Promise<void>((resolve) => {
            setTimeout(() => {
              resolve()
            }, 2000)
          })
        }
      }

      loop()

      return () => {
        inEffect = false
      }
    }
  }, [animate])

  const backgroundStyle = useAnimatedStyle(() => {
    return {
      width: size * 4,
      height: size * 4,

      transform: [
        { scale: interpolate(backgroundAnimation.value, [0, 1], [1.5, 1]) },
      ],

      opacity: interpolate(
        backgroundAnimation.value,
        [0, 1],
        [0, 1],
        Extrapolate.CLAMP
      ),
    }
  }, [])

  const leftStyle = useAnimatedStyle(() => {
    return {
      width: size * 4,
      height: size * 4,
      position: 'absolute',

      // transform: `translateX(${interpolate(
      //   leftAnimation.value,
      //   [0, 1],
      //   [size * -2, 0]
      // )}px) scale(${interpolate(leftAnimation.value, [0, 1], [1.5, 1])})`,
      opacity: interpolate(
        leftAnimation.value,
        [0, 1],
        [0, 1],
        Extrapolate.CLAMP
      ),
      filter: `blur(${interpolate(leftAnimation.value, [0, 1], [1, 0])}px)`,
    }
  }, [size])
  const rightTopStyle = useAnimatedStyle(() => {
    return {
      width: size * 4,
      height: size * 4,
      position: 'absolute',

      // transform: `translateY(${interpolate(
      //   rightTopAnimation.value,
      //   [0, 1],
      //   [size * -2, 0]
      // )}px) scale(${interpolate(rightTopAnimation.value, [0, 1], [1.5, 1])})`,
      opacity: interpolate(
        rightTopAnimation.value,
        [0, 1],
        [0, 1],
        Extrapolate.CLAMP
      ),
      filter: `blur(${interpolate(rightTopAnimation.value, [0, 1], [1, 0])}px)`,
    }
  }, [size])
  const rightBottomStyle = useAnimatedStyle(() => {
    return {
      width: size * 4,
      height: size * 4,
      position: 'absolute',

      // transform: `translateY(${interpolate(
      //   rightBottomAnimation.value,
      //   [0, 1],
      //   [size * 2, 0]
      // )}px) scale(${interpolate(
      //   rightBottomAnimation.value,
      //   [0, 1],
      //   [1.5, 1]
      // )})`,
      opacity: interpolate(
        rightBottomAnimation.value,
        [0, 1],
        [0, 1],
        Extrapolate.CLAMP
      ),
      filter: `blur(${interpolate(
        rightBottomAnimation.value,
        [0, 1],
        [1, 0]
      )}px)`,
    }
  }, [size])

  return (
    <Animated.View style={backgroundStyle}>
      <Animated.View
        style={{ width: size * 4, height: size * 4, position: 'absolute' }}
      >
        <Image
          defaultSource={logoImageBackground}
          style={{ width: '100%', height: '100%' }}
        />
      </Animated.View>

      <Animated.View style={leftStyle}>
        <Image
          defaultSource={logoIconLeft}
          style={{ width: '100%', height: '100%' }}
        />
      </Animated.View>

      <Animated.View style={rightTopStyle}>
        <Image
          defaultSource={logoIconRightTop}
          style={{ width: '100%', height: '100%' }}
        />
      </Animated.View>

      <Animated.View style={rightBottomStyle}>
        <Image
          defaultSource={logoIconRightBottom}
          style={{ width: '100%', height: '100%' }}
        />
      </Animated.View>
    </Animated.View>
  )
}
