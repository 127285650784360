const CHARS_TO_ENCODE = /[\u007f-\uffff]/g

/**
 * Need to escape unicode characters before sending as http header
 */
export function httpHeaderSafe(value: string) {
  return value.replace(CHARS_TO_ENCODE, function (c) {
    return '\\u' + ('000' + c.charCodeAt(0).toString(16)).slice(-4)
  })
}
