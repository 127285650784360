import { transparentize } from 'polished'

const BASE_FONT_SIZE = 16
const BASE_SPACE_SIZE = 4

// const SCALE_RATIO = (1 + Math.sqrt(5)) / 2 // Golden Ratio
const SCALE_RATIO = (1 + Math.pow(5, 0.25)) / 2 // "Half-step" Golden Ratio
// const SCALE_RATIO = 9 / 8

const createFontSizeScale = (baseScaleUnit: number, scaleRatio: number) =>
  Array.from({
    0: 0,
    1: baseScaleUnit / Math.pow(scaleRatio, 4),
    2: baseScaleUnit / Math.pow(scaleRatio, 3),
    3: baseScaleUnit / Math.pow(scaleRatio, 2),
    4: baseScaleUnit / scaleRatio,
    5: baseScaleUnit,
    6: baseScaleUnit * scaleRatio,
    7: baseScaleUnit * Math.pow(scaleRatio, 2),
    8: baseScaleUnit * Math.pow(scaleRatio, 3),
    9: baseScaleUnit * Math.pow(scaleRatio, 4),
    10: baseScaleUnit * Math.pow(scaleRatio, 5),
    11: baseScaleUnit * Math.pow(scaleRatio, 6),
    12: baseScaleUnit * Math.pow(scaleRatio, 7),
    13: baseScaleUnit * Math.pow(scaleRatio, 8),
    14: baseScaleUnit * Math.pow(scaleRatio, 9),
    15: baseScaleUnit * Math.pow(scaleRatio, 10),
    16: baseScaleUnit * Math.pow(scaleRatio, 11),
    17: baseScaleUnit * Math.pow(scaleRatio, 12),
    18: baseScaleUnit * Math.pow(scaleRatio, 13),
    length: 19,
  })

// const createColourScale = (startColour: string, endColour: string) =>
//   Array.from({
//     0: startColour,
//     1: mix(0.1, endColour, startColour),
//     2: mix(0.2, endColour, startColour),
//     3: mix(0.3, endColour, startColour),
//     4: mix(0.4, endColour, startColour),
//     5: mix(0.5, endColour, startColour),
//     6: mix(0.6, endColour, startColour),
//     7: mix(0.7, endColour, startColour),
//     8: mix(0.8, endColour, startColour),
//     9: mix(0.9, endColour, startColour),
//     10: endColour,
//     length: 11,
//   })

/**
 * Please do NOT refactor these opacity values. We are using babel-plugin-polished to optimize bundle size,
 *   and the polished function arguments cannot be dynamic, they must be Literal values.
 */
const lightBlueOpacity = [
  transparentize(1.0, '#6E33EB'),
  transparentize(0.9, '#6E33EB'),
  transparentize(0.8, '#6E33EB'),
  transparentize(0.7, '#6E33EB'),
  transparentize(0.6, '#6E33EB'),
  transparentize(0.5, '#6E33EB'),
  transparentize(0.4, '#6E33EB'),
  transparentize(0.3, '#6E33EB'),
  transparentize(0.2, '#6E33EB'),
  transparentize(0.1, '#6E33EB'),
  '#6E33EB',
]
const darkBlueOpacity = [
  transparentize(1.0, '#5533EB'),
  transparentize(0.9, '#5533EB'),
  transparentize(0.8, '#5533EB'),
  transparentize(0.7, '#5533EB'),
  transparentize(0.6, '#5533EB'),
  transparentize(0.5, '#5533EB'),
  transparentize(0.4, '#5533EB'),
  transparentize(0.3, '#5533EB'),
  transparentize(0.2, '#5533EB'),
  transparentize(0.1, '#5533EB'),
  '#5533EB',
]
const redOpacity = [
  transparentize(1.0, '#FF627E'),
  transparentize(0.9, '#FF627E'),
  transparentize(0.8, '#FF627E'),
  transparentize(0.7, '#FF627E'),
  transparentize(0.6, '#FF627E'),
  transparentize(0.5, '#FF627E'),
  transparentize(0.4, '#FF627E'),
  transparentize(0.3, '#FF627E'),
  transparentize(0.2, '#FF627E'),
  transparentize(0.1, '#FF627E'),
  '#FF627E',
]

export const space = new Proxy([], {
  get: (target, prop) => {
    if (
      typeof prop === 'number' ||
      (typeof prop === 'string' &&
        !isNaN(prop as unknown as number) &&
        !isNaN(parseFloat(prop)))
    ) {
      return parseFloat(prop.toString()) * BASE_SPACE_SIZE
    }

    return target[prop]
  },
})

export const colours: Record<string, string[]> = {
  // blue: createColourScale('#0000ff', '#000000'),
  // green: createColourScale('#00FF00', '#000000'),
  // grey: createColourScale('#ffffff', '#000000'),
  grey: [
    '#FFFFFF',
    '#F8F7FC',
    '#F4F2FA',
    '#E9E7F1',
    '#D5D3DD',
    '#C5C3CF',
    '#9A97A8',
    '#696676',
    '#1B1340',
    '#000000',
  ],
  // lightGrey: createColourScale('#ffffff', '#7F7F7F'),
  // darkGrey: createColourScale('#7F7F7F', '#000000'),
  // lightBlue: createColourScale('#ffffff', '#0000ff'),
  lightBlue: lightBlueOpacity,
  darkBlue: darkBlueOpacity,
  red: redOpacity,
}

export const tokens = {
  fontSize: createFontSizeScale(BASE_FONT_SIZE, SCALE_RATIO),
  lineHeight: createFontSizeScale(BASE_FONT_SIZE * SCALE_RATIO, SCALE_RATIO),
  color: colours,
  loadingColor: colours,
  backgroundColor: colours,
  borderColor: colours,
  borderWidth: space,
  borderRadius: space,
  borderTopLeftRadius: space,
  borderTopRightRadius: space,
  borderBottomLeftRadius: space,
  borderBottomRightRadius: space,
  borderBottomWidth: space,
  borderBottomColor: colours,
  outlineColor: colours,
  outlineWidth: space,
  shadowColor: colours,
  shadowRadius: space,
  marginTop: space,
  marginBottom: space,
  marginLeft: space,
  marginRight: space,
  margin: space,
  marginStart: space,
  marginEnd: space,
  padding: space,
  paddingStart: space,
  paddingEnd: space,
  paddingLeft: space,
  paddingRight: space,
  paddingTop: space,
  paddingBottom: space,
  paddingVertical: space,
  paddingHorizontal: space,
  bottom: space,
  left: space,
  right: space,
  top: space,
  height: space,
  maxHeight: space,
  minHeight: space,
  width: space,
  maxWidth: space,
  minWidth: space,
  size: space,
  // mobile, tablet, wide, desktop
  breakpoints: [576, 768, 960, 1200],
} as const
