import React, { FC, useState, useEffect } from 'react'
import {
  Image as RNImage,
  ImageProps as RNImageProps,
  ImageSourcePropType,
} from 'react-native'

import { Element } from './Element'

type ImageProps = {
  variant?: string
  source: ImageSourcePropType | string
  responsive?: boolean
  size?: number
  alt?: string
} & Pick<RNImageProps, 'style'>

export const Image: FC<ImageProps> = ({
  variant,
  source,
  responsive = false,
  size,
  ...props
}) => {
  const [imageSize, setImageSize] = useState({
    width: 2,
    height: 1,
  })

  const [dimensions, setDimensions] = useState({ width: 2, height: 1 })

  useEffect(() => {
    if (!responsive) {
      return
    }

    if (typeof source === 'number' || Array.isArray(source)) {
      return
    }

    const onImageSize = (width, height) => {
      setImageSize({ width, height })
    }

    if (typeof source === 'string') {
      RNImage.getSize(source, onImageSize)

      return
    }

    RNImage.getSize(source.uri, onImageSize)
  }, [source, setImageSize, responsive])

  const onLayout = (event) => {
    const { width, height } = event.nativeEvent.layout

    setDimensions({ width, height })
  }

  return (
    <Element
      {...props}
      component='Image'
      variant={variant}
      as={RNImage}
      onLayout={onLayout}
      source={source}
      tokens={size ? { width: size, height: size } : undefined}
      style={[
        props.style,
        responsive
          ? {
              width: '100%',
              minHeight:
                imageSize.height * (dimensions.width / imageSize.width),
            }
          : null,
      ]}
    />
  )
}
