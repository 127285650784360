import React, { FC, ComponentProps } from 'react'
import { Text as RNText } from 'react-native'

import { Element } from './Element'

export interface TextProps {
  size?: number | number[]
  lineHeight?: number | number[]
  variant?: string
  onLayout?: ComponentProps<typeof RNText>['onLayout']
  numberOfLines?: ComponentProps<typeof RNText>['numberOfLines']
  textAlign?: 'left' | 'center' | 'right'
  children: React.ReactNode
}

export const Text = ({
  size = 5,
  lineHeight = size,
  variant,
  textAlign,
  ...props
}: TextProps) => {
  return (
    <Element
      {...props}
      tokens={{ fontSize: size, lineHeight, textAlign }}
      as={RNText}
      component='Text'
      variant={variant}
    />
  )
}

export const LinkText = ({
  children,
  numberOfLines,
  size = 5,
  variant,
  textAlign,
}: TextProps) => {
  return (
    <Element
      as={RNText}
      component='LinkText'
      numberOfLines={numberOfLines}
      tokens={{ fontSize: size, lineHeight: size, textAlign }}
      variant={variant}
    >
      {children}
    </Element>
  )
}
