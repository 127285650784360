import React, {
  FC,
  ComponentPropsWithRef,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import {
  View,
  TouchableOpacity,
  GestureResponderEvent,
  Platform,
  StyleProp,
  ViewStyle,
} from 'react-native'
// Ordinary Expo Linking creates a build issue for Cloudflare
import Linking from 'expo-linking/build/ExpoLinking'
import { NavigateOptions, useNavigate } from 'react-router-dom'

import { Element } from './Element'
import { Stack } from './Stack'

import { useCreateLinkPressHandler } from '@newComponents'

export interface LinkProps {
  beforeNavigate?: () => void
  to: string
}
type PressEvent =
  | React.MouseEvent<HTMLAnchorElement, MouseEvent>
  | GestureResponderEvent
type TouchableOpacityRef = ComponentPropsWithRef<typeof TouchableOpacity>['ref']

/**
 * Link wrapper for app internal links.
 */
export const Link = (
  props: LinkProps & { options?: NavigateOptions; children: React.ReactNode }
) => {
  const { beforeNavigate, children, options, to } = props

  const navigate = useNavigate()
  const linkAction = useCallback(() => {
    beforeNavigate?.()
    navigate(to, options)
  }, [options, to])

  const { anchorRef, clickableRef } = useCreateLinkPressHandler(linkAction)

  return (
    <Stack>
      <TouchableOpacity
        // @ts-expect-error ref prop incompatible
        ref={clickableRef}
      >
        <Element
          {...props}
          accessibilityRole='link'
          as={View}
          href={to}
          ref={anchorRef}
        >
          {children}
        </Element>
      </TouchableOpacity>
    </Stack>
  )
}

const hrefAttrs = { target: '_blank' }

/**
 * Link wrapper for external links.
 */
export const ExternalLink = (
  props: LinkProps & { children: React.ReactNode }
) => {
  const { children, to } = props

  const linkAction = useCallback(() => Linking.openURL(to), [to])
  const { linkPressHandler, anchorRef } = useCreateLinkPressHandler(linkAction)

  return (
    <Stack>
      <TouchableOpacity onPress={linkPressHandler}>
        <Element
          {...props}
          accessibilityRole='link'
          as={View}
          href={to}
          hrefAttrs={hrefAttrs}
          ref={anchorRef}
        >
          {children}
        </Element>
      </TouchableOpacity>
    </Stack>
  )
}
