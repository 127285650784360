import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  Suspense,
} from 'react'
import {
  Pressable,
  View,
  LayoutChangeEvent,
  LayoutRectangle,
  TouchableOpacity,
} from 'react-native'
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  withSpring,
  Easing,
} from 'react-native-reanimated'

import { LogoAnimated } from '../Logo/LogoAnimated'
import { LogoIconAnimated } from '../Logo/LogoIconAnimated'
import { Element } from '../Element'
import { Stack } from '../Stack'
import { Button } from '../Button'
import { Text } from '../Text'
import { Link } from '../Link'
import { Breakpoint } from '../Breakpoint'
import { useBreakpoint } from '../useBreakpoint'
import { Icon } from '../Icon'

import { SuspenseWrapperLoadingSpinner } from '../SuspenseWrapper'

import { ComponentsContext, TokensContext, BreakpointContext } from '../context'
import { applyStyles } from '../util/applyStyles'

import { selectBreakpointToken } from '../util/selectBreakpointToken'

import { DynamicIslandHome } from './DynamicIslandHome'
import { DynamicIslandAdd } from './DynamicIslandAdd'
import { DynamicIslandProfile } from './DynamicIslandProfile'

import { DynamicIslandProfile_query$key } from './__generated__/DynamicIslandProfile_query.graphql'

const MENU_TITLE = {
  home: 'Home',
  add: 'Create',
  profile: 'Profile',
}

export const DynamicIsland = ({
  query,
  isLoading = false,
}: {
  query: DynamicIslandProfile_query$key
  isLoading?: boolean
}) => {
  const tokensContext = useContext(TokensContext)
  const componentsContext = useContext(ComponentsContext)
  const breakpointContext = useContext(BreakpointContext)

  const [isHovered, setIsHovered] = useState(false)

  const [expandedDropdown, setExpandedDropdown] = useState<
    'home' | 'add' | 'profile' | null
  >(null)

  const [islandDimensions, setIslandDimensions] =
    useState<LayoutRectangle | null>(null)

  const [homeButtonDimensions, setHomeButtonDimensions] =
    useState<LayoutRectangle | null>(null)

  const [addButtonDimensions, setAddButtonDimensions] =
    useState<LayoutRectangle | null>(null)

  const [profileButtonDimensions, setProfileButtonDimensions] =
    useState<LayoutRectangle | null>(null)

  const [homeDropDownDimensions, setHomeDropDownDimensions] =
    useState<LayoutRectangle | null>(null)

  const [addDropDownDimensions, setAddDropDownDimensions] =
    useState<LayoutRectangle | null>(null)

  const [profileDropDownDimensions, setProfileDropDownDimensions] =
    useState<LayoutRectangle | null>(null)

  const breakpoint = useBreakpoint()

  const dynamicIslandStyle = useMemo(() => {
    return applyStyles(
      tokensContext,
      componentsContext,
      breakpointContext,
      {},
      'DynamicIsland',
      isHovered ? 'hover' : undefined
    )
  }, [tokensContext, componentsContext, breakpointContext, isHovered])

  const backgroundColorAnimation = useSharedValue(
    dynamicIslandStyle.backgroundColor
  )

  const topAnimation = useSharedValue(0)
  const leftAnimation = useSharedValue(0)
  const heightAnimation = useSharedValue(0)
  const widthAnimation = useSharedValue(0)

  const mainIslandLeft = useSharedValue(0)
  const mainIslandOpacity = useSharedValue(1.0)

  const homeButtonOpacity = useSharedValue(1.0)
  const homeButtonLeft = useSharedValue(0)

  const addButtonOpacity = useSharedValue(1.0)
  const addButtonRotation = useSharedValue(0)
  const addButtonLeft = useSharedValue(0)

  const profileButtonOpacity = useSharedValue(1.0)
  const profileButtonLeft = useSharedValue(0)

  const dropdownOpacity = useSharedValue(0.0)

  useEffect(() => {
    backgroundColorAnimation.value = withTiming(
      dynamicIslandStyle.backgroundColor,
      { duration: 250, easing: Easing.bezier(0, 0.5, 1, 1) }
    )
  }, [dynamicIslandStyle])

  useEffect(() => {
    if (!islandDimensions) {
      return
    }

    const springOptions = { mass: 0.15, stiffness: 60, damping: 5 }

    if (expandedDropdown) {
      if (
        expandedDropdown === 'home' &&
        homeDropDownDimensions &&
        homeButtonDimensions
      ) {
        heightAnimation.value = withSpring(
          islandDimensions.height +
            homeDropDownDimensions.height +
            homeDropDownDimensions.y,
          springOptions
        )
        widthAnimation.value = withSpring(
          homeDropDownDimensions.width,
          springOptions
        )

        leftAnimation.value = withSpring(
          homeButtonDimensions.left -
            (homeDropDownDimensions.width - homeButtonDimensions.width) -
            islandDimensions.left +
            8,

          springOptions
        )

        mainIslandLeft.value = withSpring(
          -islandDimensions.width,
          springOptions
        )
        mainIslandOpacity.value = withSpring(0, {
          ...springOptions,
          overshootClamping: true,
        })

        homeButtonOpacity.value = withSpring(1.0, {
          ...springOptions,
          overshootClamping: true,
        })
        homeButtonLeft.value = 0

        addButtonOpacity.value = withSpring(0.0, {
          ...springOptions,
          overshootClamping: true,
        })
        addButtonLeft.value = withSpring(
          homeButtonDimensions.left -
            (homeDropDownDimensions.width - homeButtonDimensions.width) -
            islandDimensions.left +
            8,

          springOptions
        )

        profileButtonOpacity.value = withSpring(0.0, {
          ...springOptions,
          overshootClamping: true,
        })
        profileButtonLeft.value = withSpring(
          homeButtonDimensions.left -
            (homeDropDownDimensions.width - homeButtonDimensions.width) -
            islandDimensions.left +
            8,

          springOptions
        )
      }

      if (
        expandedDropdown === 'add' &&
        addButtonDimensions &&
        addDropDownDimensions
      ) {
        heightAnimation.value = withSpring(
          islandDimensions.height +
            addDropDownDimensions.height +
            addDropDownDimensions.y,
          springOptions
        )
        widthAnimation.value = withSpring(
          addDropDownDimensions.width,
          springOptions
        )

        leftAnimation.value = withSpring(
          addButtonDimensions.left -
            (addDropDownDimensions.width - addButtonDimensions.width) -
            islandDimensions.left +
            8,

          springOptions
        )

        homeButtonLeft.value = withSpring(
          addButtonDimensions.left -
            (addDropDownDimensions.width - addButtonDimensions.width) -
            islandDimensions.left +
            8,

          springOptions
        )

        addButtonLeft.value = 0
        addButtonRotation.value = withSpring(45, springOptions)

        profileButtonOpacity.value = withSpring(0.0, {
          ...springOptions,
          overshootClamping: true,
        })
        profileButtonLeft.value = withSpring(
          addButtonDimensions.left -
            (addDropDownDimensions.width - addButtonDimensions.width) -
            islandDimensions.left,

          springOptions
        )

        mainIslandLeft.value = withSpring(
          -islandDimensions.width,
          springOptions
        )
        mainIslandOpacity.value = withSpring(0, {
          ...springOptions,
          overshootClamping: true,
        })

        homeButtonOpacity.value = withSpring(0.0, {
          ...springOptions,
          overshootClamping: true,
        })
        addButtonOpacity.value = withSpring(1.0, {
          ...springOptions,
          overshootClamping: true,
        })
      }

      if (
        expandedDropdown === 'profile' &&
        profileButtonDimensions &&
        profileDropDownDimensions
      ) {
        heightAnimation.value = withSpring(
          islandDimensions.height +
            profileDropDownDimensions.height +
            profileDropDownDimensions.y,
          springOptions
        )
        widthAnimation.value = withSpring(
          profileDropDownDimensions.width,
          springOptions
        )

        leftAnimation.value = withSpring(
          profileButtonDimensions.left -
            (profileDropDownDimensions.width - profileButtonDimensions.width) -
            islandDimensions.left +
            8,

          springOptions
        )

        mainIslandLeft.value = withSpring(
          -islandDimensions.width,
          springOptions
        )
        mainIslandOpacity.value = withSpring(0, {
          ...springOptions,
          overshootClamping: true,
        })

        homeButtonOpacity.value = withSpring(0.0, {
          ...springOptions,
          overshootClamping: true,
        })
        homeButtonLeft.value = withSpring(
          profileButtonDimensions.left -
            (profileDropDownDimensions.width - profileButtonDimensions.width) -
            islandDimensions.left,

          springOptions
        )

        addButtonOpacity.value = withSpring(0.0, {
          ...springOptions,
          overshootClamping: true,
        })
        addButtonLeft.value = withSpring(
          profileButtonDimensions.left -
            (profileDropDownDimensions.width - profileButtonDimensions.width) -
            islandDimensions.left,

          springOptions
        )

        profileButtonOpacity.value = 1.0
        profileButtonLeft.value = 0
      }

      dropdownOpacity.value = withSpring(1.0, {
        ...springOptions,
        overshootClamping: true,
      })
    } else {
      heightAnimation.value = withSpring(islandDimensions.height, springOptions)
      widthAnimation.value = withSpring(islandDimensions.width, springOptions)
      leftAnimation.value = withSpring(0, springOptions)

      mainIslandLeft.value = withSpring(0, springOptions)
      mainIslandOpacity.value = withSpring(1.0, {
        ...springOptions,
        overshootClamping: true,
      })

      addButtonRotation.value = withSpring(0, springOptions)

      dropdownOpacity.value = withSpring(0.0, {
        ...springOptions,
        overshootClamping: true,
      })

      homeButtonLeft.value = withSpring(0.0, springOptions)
      homeButtonOpacity.value = withSpring(1.0, {
        ...springOptions,
        overshootClamping: true,
      })

      addButtonLeft.value = withSpring(0.0, springOptions)
      addButtonOpacity.value = withSpring(1.0, {
        ...springOptions,
        overshootClamping: true,
      })

      profileButtonLeft.value = withSpring(0.0, springOptions)
      profileButtonOpacity.value = withSpring(1.0, {
        ...springOptions,
        overshootClamping: true,
      })
    }
  }, [
    expandedDropdown,
    homeButtonDimensions,
    homeDropDownDimensions,
    addButtonDimensions,
    addDropDownDimensions,
    profileButtonDimensions,
    profileDropDownDimensions,
    islandDimensions,
  ])

  const backgroundStyle = useAnimatedStyle(() => {
    const commonStyles = {
      backgroundColor: 'rgba(255, 255, 255, 0.75)',
      overflow: 'hidden',
      boxShadow:
        'rgba(27, 19, 64, 0.08) 0px 8px 8px -2px, rgba(27, 19, 64, 0.05) 0px 15px 25px 10px',
      // shadowColor: 'rgba(27, 19, 64, 0.08)',
      // shadowOffset: { height: 6, width: 0 },
      // shadowRadius: 4,
      position: 'absolute',
      borderRadius: 24,
      borderColor: 'rgba(19, 0, 102, 0.08)',
      borderWidth: 0.25,
      zIndex: 0,

      WebkitBackdropFilter: 'blur(8px) saturate(250%)',
      backdropFilter: 'blur(8px) saturate(250%)',
    }

    if (!islandDimensions) {
      return {
        ...commonStyles,

        backgroundColor: backgroundColorAnimation.value,
        width: selectBreakpointToken(breakpoint, [243, 418]),
        height: 48,
        transform: [
          { translateX: leftAnimation.value },
          { translateY: topAnimation.value },
          { translateZ: 0 },
        ],
      }
    }

    return {
      ...commonStyles,

      backgroundColor: backgroundColorAnimation.value,

      height: heightAnimation.value,
      width: widthAnimation.value,

      transform: [
        { translateX: leftAnimation.value },
        { translateY: topAnimation.value },
        { translateZ: 0 },
      ],
    }
  }, [breakpoint, islandDimensions])

  const outerIslandStyle = useAnimatedStyle(() => {
    if (!islandDimensions) {
      return {}
    }

    return {
      clipPath: `inset(${topAnimation.value}px ${
        islandDimensions.width - (leftAnimation.value + widthAnimation.value)
      }px ${
        islandDimensions.height - (topAnimation.value + heightAnimation.value)
      }px ${leftAnimation.value}px)`,
    }
  }, [islandDimensions])

  const mainIslandStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: mainIslandLeft.value }],
      opacity: mainIslandOpacity.value,
    }
  }, [])

  const dropDownStyle = useAnimatedStyle(() => {
    const commonStyles = {
      position: 'relative',
      right: 0,
    }

    if (!islandDimensions || !expandedDropdown) {
      return {
        transform: [{ translateX: 0 }],
        opacity: dropdownOpacity.value,
      }
    }

    if (
      expandedDropdown === 'home' &&
      homeDropDownDimensions &&
      homeButtonDimensions
    ) {
      return {
        ...commonStyles,

        transform: [
          {
            translateX: -(
              homeButtonDimensions.left -
              (homeDropDownDimensions.width - homeButtonDimensions.width) -
              islandDimensions.left -
              leftAnimation.value
            ),
          },
        ],
        opacity: dropdownOpacity.value,
      }
    }

    if (
      expandedDropdown === 'add' &&
      addDropDownDimensions &&
      addButtonDimensions
    ) {
      return {
        ...commonStyles,

        transform: [
          {
            translateX: -(
              addButtonDimensions.left -
              (addDropDownDimensions.width - addButtonDimensions.width) -
              islandDimensions.left -
              leftAnimation.value
            ),
          },
        ],
        opacity: dropdownOpacity.value,
      }
    }

    if (
      expandedDropdown === 'profile' &&
      profileDropDownDimensions &&
      profileButtonDimensions
    ) {
      return {
        ...commonStyles,

        transform: [
          {
            translateX: -(
              profileButtonDimensions.left -
              (profileDropDownDimensions.width -
                profileButtonDimensions.width) -
              islandDimensions.left -
              leftAnimation.value
            ),
          },
        ],
        opacity: dropdownOpacity.value,
      }
    }

    return {
      transform: [{ translateX: 0 }],
      opacity: dropdownOpacity.value,
    }
  }, [
    islandDimensions,
    homeButtonDimensions,
    homeDropDownDimensions,
    addDropDownDimensions,
    addButtonDimensions,
    profileDropDownDimensions,
    profileButtonDimensions,
    expandedDropdown,
  ])

  useEffect(() => {
    if (breakpoint !== 0 && expandedDropdown === 'home') {
      setExpandedDropdown(null)
    }
  }, [breakpoint, expandedDropdown, setExpandedDropdown])

  useEffect(() => {
    const supportsHover =
      typeof window !== 'undefined' &&
      !window.matchMedia('(hover: none)').matches

    if (expandedDropdown && (!isHovered || !supportsHover)) {
      const timeoutId = setTimeout(() => {
        setExpandedDropdown(null)
        setIsHovered(false)
      }, 5000)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [expandedDropdown, setExpandedDropdown, isHovered, setIsHovered])

  const dropDownHeaderStyle = useAnimatedStyle(() => {
    return {
      position: 'absolute',
      transform: [{ translateX: leftAnimation.value + 8 }],
      opacity: dropdownOpacity.value,
    }
  }, [])

  const homeButtonStyle = useAnimatedStyle(() => {
    return {
      opacity: homeButtonOpacity.value,
      visibility: homeButtonOpacity.value === 0.0 ? 'hidden' : 'visible',
      transform: [{ translateX: homeButtonLeft.value }],
    }
  }, [])

  const addButtonContainerStyle = useAnimatedStyle(() => {
    return {
      opacity: addButtonOpacity.value,
      visibility: addButtonOpacity.value === 0.0 ? 'hidden' : 'visible',
      transform: [{ translateX: addButtonLeft.value }],
    }
  }, [])

  const addButtonStyle = useAnimatedStyle(() => {
    return {
      transform: [{ rotate: `${addButtonRotation.value}deg` }],
    }
  }, [])

  const profileButtonContainerStyle = useAnimatedStyle(() => {
    return {
      // opacity: addButtonOpacity.value,
      // visibility: addButtonOpacity.value === 0.0 ? 'hidden' : 'visible',
      opacity: profileButtonOpacity.value,
      transform: [{ translateX: profileButtonLeft.value }],
    }
  }, [])

  const onIslandLayout = useCallback(
    (event: LayoutChangeEvent) => {
      setIslandDimensions(event.nativeEvent.layout)

      heightAnimation.value = event.nativeEvent.layout.height
      widthAnimation.value = event.nativeEvent.layout.width
    },
    [setIslandDimensions]
  )

  const onHomeButtonLayout = useCallback(
    (event) => {
      setHomeButtonDimensions(event.nativeEvent.layout)
    },
    [setHomeButtonDimensions]
  )

  const onHomeDropDownLayout = useCallback(
    (event: LayoutChangeEvent) => {
      setHomeDropDownDimensions(event.nativeEvent.layout)
    },
    [setHomeDropDownDimensions]
  )

  const onHomeButtonPress = useCallback(() => {
    if (expandedDropdown === 'home') {
      setExpandedDropdown(null)
      setIsHovered(false)
    } else {
      setExpandedDropdown('home')
      setIsHovered(true)
    }
  }, [expandedDropdown, setExpandedDropdown, setIsHovered])

  const onAddButtonLayout = useCallback(
    (event) => {
      setAddButtonDimensions(event.nativeEvent.layout)
    },
    [setAddButtonDimensions]
  )

  const onAddDropDownLayout = useCallback(
    (event: LayoutChangeEvent) => {
      setAddDropDownDimensions(event.nativeEvent.layout)
    },
    [setAddDropDownDimensions]
  )

  const onAddButtonPress = useCallback(() => {
    if (expandedDropdown === 'add') {
      setExpandedDropdown(null)
      setIsHovered(false)
    } else {
      setExpandedDropdown('add')
      setIsHovered(true)
    }
  }, [expandedDropdown, setExpandedDropdown, setIsHovered])

  const onProfileButtonLayout = useCallback(
    (event) => {
      setProfileButtonDimensions(event.nativeEvent.layout)
    },
    [setProfileButtonDimensions]
  )

  const onProfileDropDownLayout = useCallback(
    (event) => {
      setProfileDropDownDimensions(event.nativeEvent.layout)
    },
    [setProfileDropDownDimensions]
  )

  const onProfileButtonPress = useCallback(() => {
    if (expandedDropdown === 'profile') {
      setExpandedDropdown(null)
      setIsHovered(false)
    } else {
      setExpandedDropdown('profile')
      setIsHovered(true)
    }
  }, [expandedDropdown, setExpandedDropdown, setIsHovered])

  const onHoverEnter = useCallback(() => {
    if (
      typeof window !== 'undefined' &&
      !window.matchMedia('(hover: none)').matches
    ) {
      setIsHovered(true)
    }
  }, [setIsHovered])

  const onHoverLeave = useCallback(() => {
    if (
      typeof window !== 'undefined' &&
      !window.matchMedia('(hover: none)').matches
    ) {
      setIsHovered(false)
    }
  }, [setIsHovered])

  const closeDropDown = useCallback(() => {
    setExpandedDropdown(null)
  }, [setExpandedDropdown])

  // useEffect(() => {
  //   if (typeof window !== 'undefined' && isHovered) {
  //     console.log('ISLAND DIMENSIONS', islandDimensions)

  //     const pointerMove = ({ x, y }: PointerEvent) => {
  //       console.log('POINTER MOVE', x, y)
  //     }

  //     window.addEventListener('pointermove', pointerMove)

  //     return () => {
  //       window.removeEventListener('pointermove', pointerMove)
  //     }
  //   }
  // }, [isHovered, islandDimensions])

  return (
    <View
      style={{
        position: 'fixed',
        overflow: 'visible',
        height: 0,
        zIndex: 100,
        top: '12px',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <View>
        <Animated.View style={backgroundStyle} />

        <Animated.View style={outerIslandStyle}>
          <Breakpoint display={0}>
            <View
              style={{ padding: 8 }}
              onLayout={onIslandLayout}
              onMouseEnter={onHoverEnter}
              onMouseLeave={onHoverLeave}
            >
              <Stack gap={6} direction='horizontal' alignItems='center'>
                <Animated.View style={mainIslandStyle}>
                  <Link to='/' beforeNavigate={closeDropDown}>
                    <LogoAnimated animate={isLoading} />
                  </Link>
                </Animated.View>

                {expandedDropdown && (
                  <Animated.View style={dropDownHeaderStyle}>
                    <Text variant='heading'>
                      {MENU_TITLE[expandedDropdown]}
                    </Text>
                  </Animated.View>
                )}

                <Stack direction='horizontal' gap={2}>
                  <DynamicIslandHome
                    onButtonLayout={onHomeButtonLayout}
                    onButtonPress={onHomeButtonPress}
                    onDropDownLayout={onHomeDropDownLayout}
                    expandDropdown={expandedDropdown === 'home'}
                    setExpandedDropdown={setExpandedDropdown}
                    buttonContainerStyle={homeButtonStyle}
                    dropDownStyle={dropDownStyle}
                  />

                  <DynamicIslandAdd
                    onButtonLayout={onAddButtonLayout}
                    onButtonPress={onAddButtonPress}
                    onDropDownLayout={onAddDropDownLayout}
                    expandDropdown={expandedDropdown === 'add'}
                    setExpandedDropdown={setExpandedDropdown}
                    buttonContainerStyle={addButtonContainerStyle}
                    buttonStyle={addButtonStyle}
                    dropDownStyle={dropDownStyle}
                  />

                  <Suspense
                    fallback={
                      <Button variant='icon' hoverVariant='iconHover'>
                        <SuspenseWrapperLoadingSpinner />
                      </Button>
                    }
                  >
                    <DynamicIslandProfile
                      onButtonLayout={onProfileButtonLayout}
                      onButtonPress={onProfileButtonPress}
                      onDropDownLayout={onProfileDropDownLayout}
                      dropDownStyle={dropDownStyle}
                      expandDropdown={expandedDropdown === 'profile'}
                      setExpandedDropdown={setExpandedDropdown}
                      buttonContainerStyle={profileButtonContainerStyle}
                      query={query}
                    />
                  </Suspense>
                </Stack>
              </Stack>
            </View>
          </Breakpoint>

          <Breakpoint display={[1, 2, 3, 4]}>
            <Stack gap={2}>
              <View
                style={{ padding: 8 }}
                onLayout={onIslandLayout}
                onMouseEnter={onHoverEnter}
                onMouseLeave={onHoverLeave}
              >
                <Stack gap={8} alignItems='center' direction='horizontal'>
                  <Animated.View style={mainIslandStyle}>
                    <Stack gap={8} alignItems='center' direction='horizontal'>
                      <Link to='/' beforeNavigate={closeDropDown}>
                        <LogoIconAnimated animate={isLoading} />
                      </Link>

                      <Stack gap={4} alignItems='center' direction='horizontal'>
                        <Link to='/guilds' beforeNavigate={closeDropDown}>
                          <Text>Guilds</Text>
                        </Link>
                        <Link to='/events' beforeNavigate={closeDropDown}>
                          <Text>Events</Text>
                        </Link>
                        <Link
                          to='/presentations'
                          beforeNavigate={closeDropDown}
                        >
                          <Text>Presentations</Text>
                        </Link>
                      </Stack>
                    </Stack>
                  </Animated.View>

                  {expandedDropdown && (
                    <Animated.View style={dropDownHeaderStyle}>
                      <Text variant='heading'>
                        {MENU_TITLE[expandedDropdown]}
                      </Text>
                    </Animated.View>
                  )}

                  <Stack direction='horizontal' gap={2}>
                    <DynamicIslandAdd
                      onButtonLayout={onAddButtonLayout}
                      onButtonPress={onAddButtonPress}
                      onDropDownLayout={onAddDropDownLayout}
                      expandDropdown={expandedDropdown === 'add'}
                      setExpandedDropdown={setExpandedDropdown}
                      buttonContainerStyle={addButtonContainerStyle}
                      buttonStyle={addButtonStyle}
                      dropDownStyle={dropDownStyle}
                    />

                    <Suspense
                      fallback={
                        <Button variant='icon' hoverVariant='iconHover'>
                          <SuspenseWrapperLoadingSpinner />
                        </Button>
                      }
                    >
                      <DynamicIslandProfile
                        onButtonLayout={onProfileButtonLayout}
                        onButtonPress={onProfileButtonPress}
                        onDropDownLayout={onProfileDropDownLayout}
                        dropDownStyle={dropDownStyle}
                        expandDropdown={expandedDropdown === 'profile'}
                        setExpandedDropdown={setExpandedDropdown}
                        buttonContainerStyle={profileButtonContainerStyle}
                        query={query}
                      />
                    </Suspense>
                  </Stack>
                </Stack>
              </View>
            </Stack>
          </Breakpoint>
        </Animated.View>
      </View>
    </View>
  )
}
