import React, {
  ComponentType,
  ComponentProps,
  FC,
  Suspense,
  useContext,
  useEffect,
  useState,
} from 'react'
import { ActivityIndicator } from 'react-native'

import { useLocation } from 'react-router-dom'

// import { Helmet } from 'react-helmet-async'
import { Screen, Metadata, Element } from '@components'
import type { LoadableComponent } from '@loadable/component'

import { ServerContext } from '@data/ServerContext'
import { useAuthContext } from '@data/useAuthContext'

import { useTitle } from 'hoofd'

import { TokensContext } from './context'

interface SuspenseWrapperProps {
  fallback?: ComponentProps<typeof Suspense>['fallback']
  children: React.ReactNode
}

export const SuspenseWrapper = ({
  children,
  fallback = <FallbackScreenLoader />,
}: SuspenseWrapperProps) => {
  return <Suspense fallback={fallback}>{children}</Suspense>
}

let initialLoad = true

export const wrapSuspenseScreen = <T extends {}>(
  Comp: LoadableComponent<T>,
  suspenseProps: SuspenseWrapperProps = { children: null }
) => {
  return (props: T) => {
    const serverContext = useContext(ServerContext)
    const { accessToken } = useAuthContext()
    const location = useLocation()

    const [hasRendered, setHasRendered] = useState(!initialLoad)

    useEffect(() => {
      if (!hasRendered) {
        initialLoad = false
        setHasRendered(true)
      }
    }, [hasRendered, setHasRendered])

    if (
      process.env.NODE_ENV !== 'production' ||
      (!hasRendered && initialLoad && (accessToken || location.state.inModal))
    ) {
      return (
        <SuspenseWrapper
          fallback={
            <>
              {!serverContext && (
                <SuspenseLoadingPageTitle title='Loading... | Guild' />
              )}

              <Screen>
                <SuspenseWrapperLoadingSpinner size='large' />
              </Screen>
            </>
          }
          {...suspenseProps}
        >
          <Comp {...props} />
        </SuspenseWrapper>
      )
    }

    return <Comp {...props} />
  }
}

export const SuspenseScreen = ({ children }: { children: React.ReactNode }) => {
  const serverContext = useContext(ServerContext)

  return (
    <SuspenseWrapper
      fallback={
        <>
          {!serverContext && (
            <SuspenseLoadingPageTitle title='Loading... | Guild' />
          )}

          <Screen>
            <SuspenseWrapperLoadingSpinner size='large' />
          </Screen>
        </>
      }
    >
      {children}
    </SuspenseWrapper>
  )
}

export const wrapSuspenseComponent = <T extends {}>(
  Comp: LoadableComponent<T>,
  suspenseProps: SuspenseWrapperProps = { children: null }
) => {
  return (props: T) => {
    return (
      <SuspenseComponent {...suspenseProps}>
        {<Comp {...props} />}
      </SuspenseComponent>
    )
  }
}

export const SuspenseComponent = ({
  children,
  ...props
}: {
  children: React.ReactNode
}) => {
  return (
    <SuspenseWrapper
      fallback={<SuspenseWrapperLoadingSpinner variant='component' />}
      {...props}
    >
      {children}
    </SuspenseWrapper>
  )
}

const FallbackScreenLoader = () => {
  const tokens = useContext(TokensContext)

  return (
    <Screen>
      <SuspenseWrapperLoadingSpinner size='large' />
    </Screen>
  )
}

export const SuspenseWrapperLoadingSpinner = ({
  variant,
  size = 'small',
}: {
  variant?: string
  size?: 'small' | 'large'
}) => {
  const tokens = useContext(TokensContext)

  return (
    <Element
      as={ActivityIndicator}
      color={tokens.color.darkBlue[9]}
      size={size}
      component='SuspenseWrapperLoadingSpinner'
      variant={variant}
    />
  )
}

export const SuspenseLoadingPageTitle: FC<{ title: string }> = ({ title }) => {
  useTitle(title)

  return null
}
