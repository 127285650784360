import React, {
  createContext,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
  startTransition,
  useTransition,
  useDeferredValue,
} from 'react'
import { graphql, RelayEnvironmentProvider } from 'react-relay'
import AsyncStorage from '@react-native-async-storage/async-storage'
import {
  SafeAreaProvider,
  initialWindowMetrics,
} from 'react-native-safe-area-context'
import {
  Environment,
  FetchFunction,
  GraphQLResponse,
  Network,
  RecordSource,
  RequestParameters,
  Store,
} from 'relay-runtime'

import { captureException } from '@sentry/react'

import { AnalyticsContext } from './Analytics'

import ProviderRefreshTokenMutation from './__generated__/ProviderRefreshTokenMutation.graphql'
import { httpHeaderSafe } from './util/httpHelpers'

import { HOST_NAME } from './config/url'

const ACCESS_TOKEN_NAME = 'guild-access-token'
const REFRESH_TOKEN_NAME = 'guild-refresh-token'

export const AuthContext = createContext<{
  accessToken?: string
  refreshToken?: string
  setAuthTokens: (accessToken?: string, refreshToken?: string) => Promise<any>
  afterSignInCallback?: (
    environment: Environment,
    accessToken: string,
    refreshToken: string
  ) => void
  setAfterSignInCallback: (
    _: (
      environment: Environment,
      accessToken: string,
      refreshToken: string
    ) => void
  ) => void
}>({
  setAuthTokens: () => Promise.resolve(),
  setAfterSignInCallback: (
    _: (
      environment: Environment,
      accessToken: string,
      refreshToken: string
    ) => void
  ) => undefined,
})

export const ToastContext = createContext<{
  addToast: (message: string, type?: 'success' | 'info' | 'error') => () => void
  toasts: Array<{}>
}>({
  addToast:
    (message, type = 'info') =>
    () =>
      undefined,
  toasts: [],
})

graphql`
  mutation ProviderRefreshTokenMutation(
    $input: RefreshAuthWithTokenMutationInput!
  ) {
    refreshAuthWithToken(input: $input) {
      accessToken
      refreshToken
    }
  }
`

export interface CloudflareInfo {
  colo: string
  country: string | null
  isEUCountry: boolean
  city: string | null
  continent: string | null
  latitude: number | null
  longitude: number | null
  postalCode: string | null
  metroCode: string | null
  region: string | null
  regionCode: string | null
  timezone: string
}

export const CloudflareInfoContext = createContext<CloudflareInfo | undefined>(
  undefined
)

export const Provider = ({
  initialAccessToken,
  initialRefreshToken,
  relayEnvironment: initialRelayEnvironment,
  graphQLURL = '/graphql',
  children,
}: {
  initialAccessToken?: string
  initialRefreshToken?: string
  relayEnvironment?: Environment
  graphQLURL?: string
  children: JSX.Element
}) => {
  const [accessToken, setAccessToken] = useState<string | undefined>(
    initialAccessToken
  )
  const [refreshToken, setRefreshToken] = useState<string | undefined>(
    initialRefreshToken
  )

  const [isPending, startTransition] = useTransition()

  // // Due to the async initialization of the app, these aren't set initially
  // useEffect(() => {
  //   startTransition(() => {
  //     setAccessToken(initialAccessToken)
  //   })

  // }, [initialAccessToken])

  // useEffect(() => {
  //   startTransition(() => {
  //   setRefreshToken(initialRefreshToken)
  // })
  // }, [initialRefreshToken])

  const [rawAfterSignInCallback, rawSetAfterSignInCallback] = useState<
    | undefined
    | ((
        environment: Environment,
        accessToken: string,
        refreshToken: string
      ) => void)
  >()

  const setAndStoreAuthTokens = useCallback(
    async (accessToken?: string, refreshToken?: string) => {
      startTransition(() => {
        setAccessToken(accessToken)
        setRefreshToken(refreshToken)

        setRelayEnvironment(
          createRelayEnvironment(
            setAndStoreAuthTokens,
            graphQLURL,
            accessToken,
            refreshToken
          )
        )
      })

      const promises = []

      if (accessToken) {
        promises.push(AsyncStorage.setItem(ACCESS_TOKEN_NAME, accessToken))
      } else {
        promises.push(AsyncStorage.removeItem(ACCESS_TOKEN_NAME))
      }

      if (refreshToken) {
        promises.push(AsyncStorage.setItem(REFRESH_TOKEN_NAME, refreshToken))
      } else {
        promises.push(AsyncStorage.removeItem(REFRESH_TOKEN_NAME))
      }

      return await Promise.all(promises)
    },
    [setAccessToken, setRefreshToken]
  )

  const [relayEnvironment, setRelayEnvironment] = useState(() => {
    if (initialRelayEnvironment) {
      return initialRelayEnvironment
    }

    return createRelayEnvironment(
      setAndStoreAuthTokens,
      graphQLURL,
      accessToken,
      refreshToken
    )
  })

  const afterSignInCallback = useCallback(() => {
    if (rawAfterSignInCallback && accessToken && refreshToken) {
      return rawAfterSignInCallback(relayEnvironment, accessToken, refreshToken)
    }
  }, [rawAfterSignInCallback, relayEnvironment, accessToken, refreshToken])

  const setAfterSignInCallback = useCallback(
    (newAfterSignInCallback) => {
      // React set state will execute the immediate closure, using what that returns as the new value
      // In this case we want to set the _value of_ the function, instead of the result of the function
      return rawSetAfterSignInCallback(() => newAfterSignInCallback)
    },
    [rawSetAfterSignInCallback]
  )

  useEffect(() => {
    if (rawAfterSignInCallback && accessToken && refreshToken) {
      startTransition(() => {
        try {
          rawAfterSignInCallback(relayEnvironment, accessToken, refreshToken)
        } finally {
          setAfterSignInCallback(undefined)
        }
      })
    }
  }, [
    rawAfterSignInCallback,
    relayEnvironment,
    accessToken,
    refreshToken,
    setAfterSignInCallback,
  ])

  const contextValue = useMemo(
    () => ({
      accessToken,
      refreshToken,
      setAuthTokens: setAndStoreAuthTokens,
      afterSignInCallback: rawAfterSignInCallback
        ? afterSignInCallback
        : undefined,
      setAfterSignInCallback,
      setAndStoreAuthTokens,
    }),
    [
      accessToken,
      refreshToken,
      afterSignInCallback,
      rawAfterSignInCallback,
      setAfterSignInCallback,
      setAndStoreAuthTokens,
    ]
  )

  const toastContextValue = useMemo(() => {
    return {
      addToast: (message: string, type?: 'success' | 'info' | 'error') => () =>
        undefined,
      toasts: [],
    }
  }, [])

  const analyticsTracker = useMemo(() => {
    // if (
    //   typeof window === 'undefined' ||
    //   typeof window.fathom?.trackGoal !== 'function'
    // ) {
    return { trackEvent: (event, value) => Promise.resolve() }
    // } else {
    //   return {
    //     trackEvent: (event, value) =>
    //       new Promise<void>((resolve, reject) => {
    //         // window.fathom.trackGoal(event, value)
    //         resolve()
    //       }),
    //   }
    // }
  }, [])

  const deferredRelayEnvironment = useDeferredValue(relayEnvironment)

  const [cloudflareInfo, setCloudflareInfo] = useState<
    CloudflareInfo | undefined
  >(undefined)

  useEffect(() => {
    if (
      !cloudflareInfo &&
      // try to detect if we're in an embeddable
      ((process.env.NODE_ENV === 'production' &&
        window?.location?.host !== 'localhost') ||
        (process.env.NODE_ENV !== 'production' &&
          `${window?.location?.protocol}//${window?.location?.host}` ===
            HOST_NAME))
    ) {
      const controller = new AbortController()
      const signal = controller.signal

      ;(async () => {
        try {
          const response = await fetch(`${HOST_NAME}/cf/`, { signal })

          if (response.ok) {
            setCloudflareInfo(await response.json<CloudflareInfo>())
          }
        } catch (err) {
          console.error('ERROR RETREIVING CLOUDFLARE INFO')
          console.error(err)
        }
      })()

      return () => {
        controller.abort()
      }
    }
  }, [cloudflareInfo, setCloudflareInfo])

  return (
    <CloudflareInfoContext.Provider value={cloudflareInfo}>
      <AuthContext.Provider value={contextValue}>
        <ToastContext.Provider value={toastContextValue}>
          <RelayEnvironmentProvider environment={deferredRelayEnvironment}>
            <AnalyticsContext.Provider value={analyticsTracker}>
              <SafeAreaProvider
                initialMetrics={
                  initialWindowMetrics || {
                    frame: { x: 0, y: 0, width: 0, height: 0 },
                    insets: { top: 0, left: 0, right: 0, bottom: 0 },
                  }
                }
              >
                {children}
              </SafeAreaProvider>
            </AnalyticsContext.Provider>
          </RelayEnvironmentProvider>
        </ToastContext.Provider>
      </AuthContext.Provider>
    </CloudflareInfoContext.Provider>
  )
}

const createRelayEnvironment = (
  setAndStoreAuthTokens: (
    accessToken?: string,
    refreshToken?: string
  ) => Promise<any>,
  graphQLURL: string,
  accessToken?: string,
  refreshToken?: string
) => {
  let initialData = undefined

  // If we're in a browser environment and not authenticated, hydrate the SSR data
  if (typeof document !== 'undefined' && !accessToken) {
    try {
      initialData = JSON.parse(
        document.getElementById('__INITIAL_RELAY_DATA__')?.innerText || '{}'
      )
    } catch (err) {
      console.error('ERROR HYDRATING INITIAL RELAY DATA', err)
    }
  }

  const recordSource = new RecordSource(initialData)

  return new Environment({
    network: Network.create(
      createNetworkLayer(
        accessToken,
        refreshToken,
        setAndStoreAuthTokens,
        graphQLURL
      )
    ),
    store: new Store(recordSource, {
      gcReleaseBufferSize: 50, // Retain the last 50 operations
      queryCacheExpirationTime: 10 * 60 * 1000, // for 10 minutes
    }),
  })
}

export const createNetworkLayer = (
  accessToken: string | undefined,
  refreshToken: string | undefined,
  setAuthTokens: (accessToken?: string, refreshToken?: string) => Promise<any>,
  graphQLURL: string
): FetchFunction => {
  const fetchGraphQLQuery = async (
    accessToken: string | undefined,
    operation: RequestParameters,
    variables: Record<string, any>
  ): Promise<Response> => {
    const makeRequest = async () => {
      const baseHeaders = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(accessToken
          ? {
              Authorization: `Bearer ${accessToken}`,
            }
          : {}),
      }

      if (operation.id) {
        if (operation.operationKind === 'query') {
          const hasVariables = Object.keys(variables).length > 0

          return await fetch(`${graphQLURL}/${operation.id}`, {
            method: 'GET',
            headers: {
              ...baseHeaders,
              ...(hasVariables
                ? {
                    'x-gqlvars': httpHeaderSafe(JSON.stringify(variables)),
                  }
                : {}),
            },
            cache: hasVariables || accessToken ? 'no-cache' : 'default',
            mode: 'cors',
          })
        }

        return await fetch(`${graphQLURL}/${operation.id}`, {
          method: 'POST',
          headers: baseHeaders,
          body: JSON.stringify({ query: operation.text, variables }),
          cache: 'no-cache',
          mode: 'cors',
        })
      } else {
        return await fetch(graphQLURL, {
          method: 'POST',
          headers: baseHeaders,
          body: JSON.stringify({ query: operation.text, variables }),
          cache: 'no-cache',
          mode: 'cors',
        })
      }
    }

    const response = await makeRequest()

    if (response.status === 401) {
      try {
        const res = await fetchGraphQLQuery(
          undefined,
          ProviderRefreshTokenMutation.params,
          {
            input: { token: refreshToken },
          }
        )

        if (res.ok) {
          const { data } = await res.json()

          if (data?.refreshAuthWithToken) {
            await setAuthTokens(
              data.refreshAuthWithToken.accessToken,
              data.refreshAuthWithToken.refreshToken
            )

            return await fetchGraphQLQuery(
              data.refreshAuthWithToken.accessToken,
              operation,
              variables
            )
          }
        }
      } catch (err) {
        captureException(err)
      }

      // if we get here for whatever reason, then reset the user's auth and try the request again
      await setAuthTokens(undefined, undefined)

      return await fetchGraphQLQuery(undefined, operation, variables)
    }

    return response
  }

  return async (
    operation,
    variables,
    _cacheConfig
  ): Promise<GraphQLResponse> => {
    let retryCount = process.env.NODE_ENV === 'production' ? 3 : 10
    let lastError

    do {
      try {
        const response = await fetchGraphQLQuery(
          accessToken,
          operation,
          variables
        )

        return await response.json()
      } catch (err) {
        lastError = err

        await new Promise((resolve) => {
          setTimeout(resolve, 1000 / Math.max(retryCount, 1))
        })

        retryCount = retryCount - 1
      }
    } while (retryCount > 0)

    throw lastError
  }
}
