import React, { FC } from 'react'
import { useBreakpoint } from './useBreakpoint'

export const Breakpoint: FC<{ display: number | number[] }> = ({
  display,
  children,
}) => {
  const breakpoint = useBreakpoint()

  if (Array.isArray(display) && display.includes(breakpoint)) {
    return <>{children}</>
  }

  if (breakpoint === display) {
    return <>{children}</>
  }

  return null
}
