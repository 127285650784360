/**
 * @generated SignedSource<<a5cd4f518c160207510100f538c228f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DynamicIslandProfile_query$data = {
  readonly viewer: {
    readonly rowId: String;
    readonly slugId: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserPrimaryPhoto_user">;
  } | null | undefined;
  readonly " $fragmentType": "DynamicIslandProfile_query";
};
export type DynamicIslandProfile_query$key = {
  readonly " $data"?: DynamicIslandProfile_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"DynamicIslandProfile_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DynamicIslandProfile_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rowId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slugId",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserPrimaryPhoto_user"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "5f8e760e0e8dc824e84012aafe823294";

export default node;
