/**
 * @generated SignedSource<<3d394441ba7cd1e249658f127ffd16e9>>
 * @relayHash 32f5013f8322200a15032e135c69c304
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c368f17956b1ec8216030581364b1da136f4f6b0705f628ccc5b29f3c17ad801

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RefreshAuthWithTokenMutationInput = {
  token: string;
};
export type ProviderRefreshTokenMutation$variables = {
  input: RefreshAuthWithTokenMutationInput;
};
export type ProviderRefreshTokenMutation$data = {
  readonly refreshAuthWithToken: {
    readonly accessToken: string;
    readonly refreshToken: string;
  };
};
export type ProviderRefreshTokenMutation = {
  response: ProviderRefreshTokenMutation$data;
  variables: ProviderRefreshTokenMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RefreshAuthWithTokenMutationResult",
    "kind": "LinkedField",
    "name": "refreshAuthWithToken",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accessToken",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "refreshToken",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProviderRefreshTokenMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProviderRefreshTokenMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "c368f17956b1ec8216030581364b1da136f4f6b0705f628ccc5b29f3c17ad801",
    "metadata": {},
    "name": "ProviderRefreshTokenMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "53be670985d9279ca81c79713e48b686";

export default node;
