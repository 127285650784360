/**
 * @generated SignedSource<<c05446ea81518e00e979615b67094d11>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserPrimaryPhoto_user$data = {
  readonly primaryPhoto: {
    readonly " $fragmentSpreads": FragmentRefs<"GraphQLImage_image">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"UserDefaultPhoto_user">;
  readonly " $fragmentType": "UserPrimaryPhoto_user";
};
export type UserPrimaryPhoto_user$key = {
  readonly " $data"?: UserPrimaryPhoto_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserPrimaryPhoto_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserPrimaryPhoto_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserDefaultPhoto_user"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "primaryPhoto",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GraphQLImage_image"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "ce5e655885328c982c84d7e6fcf4ee90";

export default node;
