import { createContext } from 'react'

import { TokenValues, ComponentContextType } from './types'

export const TokensContext = createContext<TokenValues>(undefined as any)
export const ComponentsContext = createContext<ComponentContextType>(
  undefined as any
)
export const BreakpointContext = createContext<0 | 1 | 2 | 3 | 4>(0)

interface GridContextValue {
  gap: number
  isFirstChild: () => boolean
}

export const GridContext = createContext<GridContextValue | undefined>(
  undefined
)

export const DrawerContext = createContext<{ toggleDrawer: () => void }>({
  toggleDrawer: () => undefined,
})
