import { applyTokens } from './applyTokens'

export const applyStyles = (
  tokenConfig,
  componentConfig,
  breakpoint: number,
  tokens,
  componentName?: string,
  variantName?: string
) => {
  const appliedTokens = {}

  if (componentName) {
    if (!componentConfig[componentName]) {
      console.warn(
        'Component name specified but no config found',
        componentName
      )
    } else {
      const { variants, ...componentGeneralConfig } =
        componentConfig[componentName]

      Object.assign(
        appliedTokens,
        applyTokens(tokenConfig, breakpoint, componentGeneralConfig)
      )

      if (variantName && variants[variantName]) {
        Object.assign(
          appliedTokens,
          applyTokens(tokenConfig, breakpoint, variants[variantName])
        )
      }
    }
  }

  Object.assign(appliedTokens, applyTokens(tokenConfig, breakpoint, tokens))

  return appliedTokens
}
