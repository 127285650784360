/**
 * @generated SignedSource<<185388c2773a3d4017aa89e324e9323c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserDefaultPhoto_user$data = {
  readonly emailMd5: string | null | undefined;
  readonly rowId: String;
  readonly " $fragmentType": "UserDefaultPhoto_user";
};
export type UserDefaultPhoto_user$key = {
  readonly " $data"?: UserDefaultPhoto_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserDefaultPhoto_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserDefaultPhoto_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emailMd5",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "a3458426b7de0df780f7dbcb3cc140df";

export default node;
