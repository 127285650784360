import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react'
import { PixelRatio } from 'react-native'
import { graphql, useFragment } from 'react-relay'
import { Image } from '../Image'
import { pickFromDefaults } from './pickFromDefaults'

// import defaultAvatarImage1 from './images/default-avatar-1.png'
// import defaultAvatarImage2 from './images/default-avatar-2.png'
// import defaultAvatarImage3 from './images/default-avatar-3.png'
// import defaultAvatarImage4 from './images/default-avatar-4.png'

import { UserDefaultPhoto_user$key } from './__generated__/UserDefaultPhoto_user.graphql'

// const DEFAULT_AVATARS = [
//   defaultAvatarImage1,
//   defaultAvatarImage2,
//   defaultAvatarImage3,
//   defaultAvatarImage4,
// ]

const DEFAULT_AVATARS = [
  'default-avatar-1_5QE9rI7Ha3.png',
  'default-avatar-2_DT5LXnCTf.png',
  'default-avatar-3_NTdhIFeg2.png',
  'default-avatar-4_QrxDP2X-N.png',
]

const IMAGEKIT_URL =
  process.env.GUILD_ENV === 'production'
    ? 'https://ik.imagekit.io/guild/prod'
    : 'https://ik.imagekit.io/guild/staging'

export const UserDefaultPhoto: FC<{
  size?: number
  stableId?: string
  user?: UserDefaultPhoto_user$key
}> = ({ size = 10, stableId, user }) => {
  if (user) {
    return (
      <GravatarUserDefaultPhoto
        size={size}
        user={user}
        fallback={<RelayUserDefaultPhoto user={user} size={size} />}
      />
    )
  }

  const defaultAvatar = useMemo(() => {
    const avatar = pickFromDefaults(stableId, DEFAULT_AVATARS)

    return `${IMAGEKIT_URL}/${avatar}?tr=h-${size * 4 * PixelRatio.get()},w-${
      size * 4 * PixelRatio.get()
    }`
  }, [stableId])

  return (
    <Image
      size={size}
      source={defaultAvatar}
      variant={
        size >= 10 ? 'userProfilePictureLarge' : 'userProfilePictureSmall'
      }
    />
  )
}

const fragment = graphql`
  fragment UserDefaultPhoto_user on User {
    rowId
    emailMd5
  }
`
const GravatarUserDefaultPhoto: FC<{
  size?: number
  user: UserDefaultPhoto_user$key
  fallback: ReactElement
}> = ({ user, size = 10, fallback }) => {
  const data = useFragment(fragment, user)
  const [isPresent, setIsPresent] = useState<boolean | undefined>()

  const url = useMemo(() => {
    if (
      !data.emailMd5 ||
      // Don't run Gravatars in other folks local environment, due to Gravatar's CORS issues
      (process.env.NODE_ENV === 'production' &&
        window?.location?.hostname === 'localhost')
    ) {
      return null
    }
    return `//www.gravatar.com/avatar/${data.emailMd5}?d=404`
  }, [data.emailMd5])

  useEffect(() => {
    const query = async () => {
      if (!url) {
        return
      }
      try {
        const response = await fetch(url)

        setIsPresent(response.status === 200)
      } catch (err) {
        setIsPresent(false)
      }
    }
    query()
  }, [url])

  // delegate on children elements
  if (!url || !isPresent) return fallback

  return (
    <Image
      size={size}
      source={url}
      variant={
        size >= 10 ? 'userProfilePictureLarge' : 'userProfilePictureSmall'
      }
    />
  )
}

const RelayUserDefaultPhoto: FC<{
  user: UserDefaultPhoto_user$key
  size?: number
}> = ({ user, size = 10 }) => {
  const data = useFragment(fragment, user)

  const defaultAvatar = useMemo(() => {
    const avatar = pickFromDefaults(data?.rowId, DEFAULT_AVATARS)

    return `${IMAGEKIT_URL}/${avatar}?tr=h-${size * 4 * PixelRatio.get()},w-${
      size * 4 * PixelRatio.get()
    }`
  }, [data])

  return (
    <Image
      size={size}
      source={defaultAvatar}
      variant={
        size >= 10 ? 'userProfilePictureLarge' : 'userProfilePictureSmall'
      }
    />
  )
}
