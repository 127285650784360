export const HOST_NAME = (() => {
  if (process.env.GUILD_ENV === 'staging') {
    return 'https://staging.guild.host'
  }

  if (process.env.GUILD_ENV === 'production') {
    return 'https://guild.host'
  }

  return 'http://localhost:9010'
})()

export const IMAGEKIT_URL =
  process.env.GUILD_ENV === 'production'
    ? 'https://ik.imagekit.io/guild/prod'
    : 'https://ik.imagekit.io/guild/staging'
