import React, { ComponentProps } from 'react'

import { styled, getTokenValue, Token } from 'tamagui'

import { GuildIcon, GuildIconKeys } from '../components/GuildIcon/GuildIcon'

const StyledGuildIcon = styled(GuildIcon, {
  variants: {
    size: {
      '...size': (size) => {
        return {
          width: getTokenValue(size, 'size'),
          height: getTokenValue(size, 'size'),
          minWidth: getTokenValue(size, 'size'),
          minHeight: getTokenValue(size, 'size'),
        }
      },
    },
  },

  defaultVariants: {
    size: '$5',
  },
} as const)

export const Icon = StyledGuildIcon.styleable(
  (
    {
      name,

      ...props
    }: {
      name: GuildIconKeys
    } & ComponentProps<typeof StyledGuildIcon>,
    ref
  ) => {
    return <StyledGuildIcon ref={ref} {...props} name={name} />
  }
)
