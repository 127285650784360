export function selectBreakpointToken<TokenType>(
  breakpoint: number,
  token: TokenType | Array<TokenType>
): TokenType {
  if (Array.isArray(token)) {
    if (breakpoint > token.length - 1) {
      return token[token.length - 1]
    }

    return token[breakpoint]
  } else {
    return token
  }
}
