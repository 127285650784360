// @ts-ignore

// if (__DEV__) {
//   const IGNORED_WARNINGS = [
//     'Remote debugger is in a background tab which may cause apps to perform slowly',
//     'Require cycle:',
//   ]
//   const oldConsoleWarn = console.warn

//   console.warn = (...args: any[]) => {
//     if (
//       typeof args[0] === 'string' &&
//       IGNORED_WARNINGS.some((ignoredWarning) =>
//         args[0].startsWith(ignoredWarning)
//       )
//     ) {
//       return
//     }

//     // @ts-ignore
//     return oldConsoleWarn.apply(console, args)
//   }
// }

// Monkey-patch console.warn first, then require the App

import * as Sentry from '@sentry/react'

import { createElement, useEffect } from 'react'

import { createRoot, hydrateRoot } from 'react-dom/client'
import { App } from './App'
import { EmbedBrowserRouter } from './components/EmbedBrowserRouter'
import { loadableReady } from '@loadable/component'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { setLocale } from 'yup'

import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  Routes,
} from 'react-router-dom'

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ enableInp: true }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/guild\.host\/graphql/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.GUILD_ENV,
  release: process.env.SENTRY_RELEASE,
  normalizeDepth: 10,
  // debug: process.env.NODE_ENV !== 'production',
  enabled: process.env.NODE_ENV === 'production',
})

const getInitialState = (async (): Promise<{
  accessToken: string | undefined
  refreshToken: string | undefined
}> => {
  try {
    const [[_, savedAccessToken], [__, savedRefreshToken]] =
      await AsyncStorage.multiGet(['guild-access-token', 'guild-refresh-token'])

    return {
      accessToken: savedAccessToken || undefined,
      refreshToken: savedRefreshToken || undefined,
    }
  } catch (err) {
    Sentry.captureException(err)

    return {
      accessToken: undefined,
      refreshToken: undefined,
    }
  }
})()

/**
 * Configure default error messages for yup validation
 */
setLocale({
  mixed: { required: 'Required field' },
  number: {
    integer: 'Must be an integer',
    positive: 'Must be a positive number',
  },
})

const main = async () => {
  try {
    await loadableReady()
  } catch (err) {
    Sentry.captureException(err)
  } finally {
    const rootElement = document.getElementById('root')

    if (!rootElement) {
      console.error('Could not find `root` element')
      return
    }

    const BrowserApp = createElement(App, {
      ...(await getInitialState),
      Router: EmbedBrowserRouter as ({
        children,
      }: {
        children: React.ReactNode
      }) => JSX.Element,
    })

    // hydrateRoot(rootElement, BrowserApp)

    createRoot(rootElement).render(BrowserApp)
  }
}

main()
