import React, {
  FC,
  useLayoutEffect,
  useEffect,
  useState,
  useDeferredValue,
  useTransition,
} from 'react'
import { Router } from 'react-router'
import { createBrowserHistory, History, To, Location } from '@remix-run/router'

import { HOST_NAME } from '@data/config/url'

import { useTitle } from 'hoofd'

const createEmbedHistory = (
  history: History,
  forceExternalNavigation: boolean = false,
  startTransition: React.TransitionStartFunction
): History => {
  // Encode State into Query String, in order to pass through a new external URL
  const createExternalURL = (to: To, state: any) => {
    let externalURL = `${HOST_NAME}${history.createHref(to).toString()}`

    if (state?.authRedirectPath) {
      externalURL += `?state_authRedirectPath=${encodeURIComponent(
        state.authRedirectPath
      )}`
    }

    return externalURL
  }

  // Decode and replace state into history state if received on initial load
  if (history.location.search) {
    const params = new URLSearchParams(history.location.search)

    const newState: Record<string, string> = {}
    let hasEmbeddedState = false

    for (let [key, value] of params) {
      if (key.startsWith('state_')) {
        hasEmbeddedState = true
        newState[key.replace('state_', '')] = value
        params.delete(key)
      }
    }

    if (hasEmbeddedState) {
      history.replace(
        {
          ...history.location,
          search:
            Array.from(params.keys()).length > 0 ? `?${params.toString()}` : '',
        },
        newState
      )
    }
  }

  return {
    ...history,
    go(...args) {
      startTransition(() => {
        history.go(...args)
      })
    },
    push(to, state) {
      if (
        forceExternalNavigation ||
        history.location.pathname.startsWith('/embeds/')
      ) {
        window.open(createExternalURL(to, state), '_blank')
        return
      }

      startTransition(() => {
        history.push(to, state)
      })
    },
    replace(to, state) {
      if (
        forceExternalNavigation ||
        history.location.pathname.startsWith('/embeds/')
      ) {
        window.open(createExternalURL(to, state), '_blank')
        return
      }

      startTransition(() => {
        history.replace(to, state)
      })
    },
  }
}

export const EmbedBrowserRouter = ({
  children,
  forceExternalNavigation = false,
  onTransition,
}: {
  forceExternalNavigation?: boolean
  children: React.ReactNode
  onTransition?: (transitioning: boolean) => any
}) => {
  const [isPending, startTransition] = useTransition()

  const [history] = useState(() =>
    createEmbedHistory(
      createBrowserHistory({ v5Compat: true }),
      forceExternalNavigation,
      startTransition
    )
  )
  const [state, setState] = useState(() => ({
    action: history.action,
    location: history.location,
  }))

  useLayoutEffect(
    () =>
      history.listen((...args) => {
        startTransition(() => {
          setState(...args)
        })
      }),
    [history]
  )

  useEffect(() => {
    if (onTransition) {
      onTransition(isPending)
    }
  }, [onTransition, isPending])

  const deferredState = useDeferredValue(state)

  return (
    <>
      {isPending ? <LoadingTitle /> : null}
      <Router
        children={children}
        location={deferredState.location}
        navigationType={deferredState.action}
        navigator={history}
      />
    </>
  )
}

const LoadingTitle = () => {
  useTitle('Loading... | Guild')

  return null
}
