export const DEFAULT_BACKGROUND =
  'https://ik.imagekit.io/guild/social-card-default-background_jsJLeMecN.png'

export const GRADIENT_IMAGE_URL =
  'https://ik.imagekit.io/guild/default_gradient_BBOVz2tl6.png'

export const HERO_BACKGROUND_IMAGE_URL =
  'https://ik.imagekit.io/guild/hero_background_bright_6B4GXB3NT.png'

export const BACKGROUNDS = [
  'social-card-backgrounds/purple-yellow_zbyzdUESq.png',
  'social-card-backgrounds/pink-yellow_UvKUtm5f3.png',
  'social-card-backgrounds/blue-purple_F4x1_B8WX.png',
  'social-card-backgrounds/blue-teal_HwODg9qh1.png',
  'social-card-backgrounds/purple-blue_t3kh8e78Bl.png',
]
