import { selectBreakpointToken } from './selectBreakpointToken'

export const applyTokens = (
  tokenConfig: Record<string, unknown>,
  breakpoint: number,
  tokens: Record<string, unknown>
) => {
  return Object.entries(tokens).reduce((memo, [tokenKey, tokenValue]) => {
    const tokenConfigValue = tokenConfig[tokenKey]

    if (!tokenConfigValue) {
      memo[tokenKey] = selectBreakpointToken(breakpoint, tokenValue)

      return memo
    }

    if (['string', 'number'].includes(typeof tokenConfigValue)) {
      memo[tokenKey] = selectBreakpointToken(breakpoint, tokenConfigValue)

      return memo
    }

    switch (typeof tokenValue) {
      case 'number':
        memo[tokenKey] = selectBreakpointToken(
          breakpoint,
          tokenConfigValue[tokenValue] || tokenValue
        )

        return memo
      case 'string':
        if (tokenValue.includes('.')) {
          const [tokenValueKey, tokenValueLookup] = tokenValue.split('.')

          memo[tokenKey] = selectBreakpointToken(
            breakpoint,
            tokenConfigValue[tokenValueKey]
              ? tokenConfigValue[tokenValueKey][tokenValueLookup]
              : tokenValue
          )

          return memo
        } else {
          memo[tokenKey] = selectBreakpointToken(
            breakpoint,
            tokenConfigValue[tokenValue] || tokenValue
          )

          return memo
        }
    }

    // TODO: Account for token selection '.' notation
    if (Array.isArray(tokenValue)) {
      const breakpointValue = selectBreakpointToken(breakpoint, tokenValue)

      memo[tokenKey] = tokenConfigValue[breakpointValue] || breakpointValue

      return memo
    }

    console.warn('No style tokens applied', tokenKey, tokenValue)

    return memo
  }, {})
}
