import { styled, SizableText, Heading as THeading } from 'tamagui'

export const H1 = styled(THeading, {
  tag: 'h1',
  name: 'H1',
  accessibilityRole: 'header',
  fontWeight: '$7',

  style: {
    WebkitFontSmoothing: 'antialiased',
  },

  variants: {
    size: {
      '...size': (size) => {
        return {
          fontSize: size,
          lineHeight: size,
        }
      },
    },
    marginAfter: {
      '...': (lineHeight, config) => {
        return {
          marginBottom:
            config.font?.lineHeight[lineHeight].variable ?? lineHeight,
        }
      },
    },
  } as const,

  defaultVariants: {
    size: '$7',
  },
})

export const H2 = styled(THeading, {
  tag: 'h2',
  name: 'H2',
  accessibilityRole: 'header',
  fontWeight: '$7',

  style: {
    WebkitFontSmoothing: 'antialiased',
  },

  variants: {
    size: {
      '...size': (size) => {
        return {
          fontSize: size,
          lineHeight: size,
        }
      },
    },
    marginAfter: {
      '...': (lineHeight, config) => {
        return {
          marginBottom:
            config.font?.lineHeight[lineHeight].variable ?? lineHeight,
        }
      },
    },
  } as const,

  defaultVariants: {
    size: '$6',
  },
})

export const H3 = styled(THeading, {
  tag: 'h3',
  name: 'H3',
  accessibilityRole: 'header',
  fontWeight: '$6',
  style: {
    WebkitFontSmoothing: 'antialiased',
  },

  variants: {
    size: {
      '...size': (size) => {
        return {
          fontSize: size,
          lineHeight: size,
        }
      },
    },
    marginAfter: {
      '...': (lineHeight, config) => {
        return {
          marginBottom:
            config.font?.lineHeight[lineHeight].variable ?? lineHeight,
        }
      },
    },
  } as const,

  defaultVariants: {
    size: '$5',
  },
})

export const Text = styled(SizableText, {
  tag: 'span',
  name: 'Text',
  fontWeight: '$4',
  style: {
    WebkitFontSmoothing: 'antialiased',
  },

  variants: {
    size: {
      '...size': (size, config) => {
        return {
          fontSize: size,
          lineHeight: size,
        }
      },
    },
    marginAfter: {
      '...': (lineHeight, config) => {
        return {
          marginBottom:
            config.font?.lineHeight[lineHeight].variable ?? lineHeight,
        }
      },
    },
  } as const,

  defaultVariants: {
    size: '$5',
  },
})

export const Paragraph = styled(Text, {
  tag: 'p',
  name: 'Paragraph',
  fontWeight: '$4',

  variants: {
    size: {
      '...size': (size) => {
        return {
          fontSize: size,
          lineHeight: size,
        }
      },
    },
    marginAfter: {
      '...': (lineHeight, config) => {
        return {
          marginBottom:
            config.font?.lineHeight[lineHeight].variable ?? lineHeight,
        }
      },
    },
  } as const,

  defaultVariants: {
    size: '$5',
  },
})

export const Strong = styled(Text, {
  tag: 'strong',
  name: 'Strong',
  fontWeight: '$6',

  variants: {
    size: {
      '...size': (size) => {
        return {
          fontSize: size,
          lineHeight: size,
        }
      },
    },
    marginAfter: {
      '...': (lineHeight, config) => {
        return {
          marginBottom:
            config.font?.lineHeight[lineHeight].variable ?? lineHeight,
        }
      },
    },
  } as const,

  defaultVariants: {
    size: '$5',
  },
})
