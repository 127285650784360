import React, { FC, useContext } from 'react'

import { Element } from './Element'
import { TokensContext } from './context'

export interface StackProps {
  gap?: number
  direction?: 'horizontal' | 'vertical'
  justifyContent?: 'center' | 'flex-end' | 'space-between' | 'flex-start' // TODO
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'baseline' // TODO
  expand?: boolean
  testID?: string
  wrap?: boolean
  variant?: string
  children?: React.ReactNode
}

export const Stack = ({
  gap = 0,
  direction = 'vertical',
  justifyContent,
  alignItems,
  children,
  expand,
  testID,
  wrap = false,
  variant,
}: StackProps) => {
  const tokens = useContext(TokensContext)

  return (
    <Element
      component='Stack'
      variant={variant}
      style={{
        flex: expand ? 1 : undefined,
        flexDirection: direction === 'horizontal' ? 'row' : 'column',
        flexWrap: wrap ? 'wrap' : 'no-wrap',
        justifyContent,
        alignItems,
        gap: tokens.size[gap],
        maxWidth: '100%',
      }}
      testID={testID}
    >
      {children}
    </Element>
  )
}
